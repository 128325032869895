import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IContext } from "../../context/IntegrateContext.jsx";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { BsBoxArrowInRight } from "react-icons/bs";
import { AiOutlineCaretDown } from "react-icons/ai";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries.js";
import { BsEject } from "react-icons/bs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MdFilterListAlt } from "react-icons/md";
import Alerts from "../../components/Alerts.jsx";
import { MdCloudUpload } from "react-icons/md";
import { Storage } from "aws-amplify";
import * as mutations from "../../graphql/mutations.js";
import Paper from "@mui/material/Paper";
import { CContext } from "../../context/CompanyContext.jsx";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { PiNumberCircleOneFill, PiNumberCircleTwoFill } from "react-icons/pi";
import { v4 as uuidv4 } from "uuid";

export default function CreateTemplateS1({ user, signOut }) {
  const [wait, setWait] = useState(true);
  const [masterJson, setMasterJson] = useState([]);
  const [tempCompanyNames, setTempCompanyNames] = useState([]);
  const [companyName, setcompanyName] = useState("");
  const [templateType, settemplateType] = useState("");
  const [templateName, settemplateName] = useState("");
  const [country, setcountry] = useState("");
  const [countryJson, setcountryJson] = useState("");
  let companyNameList = [];
  let templateTypeList = [];
  let countryList = [];

  const handleCompanyNameChange = (event) => {
    setcompanyName(event.target.value);
  };
  const handleTemplateTypeChange = (event) => {
    settemplateType(event.target.value);
  };
  const handleTemplateNameChange = (event) => {
    settemplateName(event.target.value);
  };
  const handleCountryChange = (event) => {
    setcountry(event.target.value);
  };

  useEffect(() => {
    const getCreateTemplate1Selection = async () => {
      const createTemplate1Selection = await API.graphql({
        query: queries.readIntegrateAttributes,
      });
      setMasterJson(createTemplate1Selection.data.readIntegrateAttributes);
      setWait(false);
    };

    const getCreateTemplateCompanyNames = async () => {
      const createTemplateCompanyNames = await API.graphql({
        query: mutations.CompanyListUseridFilter,
        variables: {
          userid: user,
        },
      });
      setTempCompanyNames(
        createTemplateCompanyNames.data.CompanyListUseridFilter,
      );
      // .readIntegrateAttributes
      // setWait(false);
    };

    const getBenefitAttributes = async () => {
      const benefitAttributes = await API.graphql({
        query: queries.getCommonValues,
      });

      setcountryJson(benefitAttributes.data.getCommonValues);
      setWait(false);
    };

    getBenefitAttributes();
    getCreateTemplateCompanyNames();
    getCreateTemplate1Selection();

    // const unParsedFormDatas = localStorage.getItem("formValues");
    // const formData = JSON.parse(unParsedFormDatas);
    // console.log(formData)
    // updateTemplateData(formData);
  }, []);

  let navigate = useNavigate();

  // console.log(tempCompanyNames);
  // console.log(masterJson);
  let duplicateTemplateTypeList = [];

  for (var r of masterJson) {
    if (r.attribute_key_name === "template_type_id") {
      let tempTemplateType = {
        templateTypeId: "",
        templateTypeDisplayName: "",
      };
      tempTemplateType.templateTypeId = r.attribute_list_valid_value;
      tempTemplateType.templateTypeDisplayName =
        r.attribute_list_value_display_name;
      duplicateTemplateTypeList.push(tempTemplateType);
      // reconCompanyId.push(tempCid);
    } else {
    }
  }

  const uniqueMapCid1 = new Map();
  for (const obj of duplicateTemplateTypeList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMapCid1.has(objString)) {
      uniqueMapCid1.set(objString, true);
      templateTypeList.push(obj);
    }
  }

  for (var c of tempCompanyNames) {
    let tempCompanyName = {
      companyNameId: "",
      companyNameDisplayName: "",
    };
    tempCompanyName.companyNameId = c.company_id;
    tempCompanyName.companyNameDisplayName = c.company_name;
    companyNameList.push(tempCompanyName);
  }
  // console.log(companyNameList);

  for (var co of countryJson) {
    if (co.attribute_key_name === "country") {
      let tempCountryList = {
        countryId: "",
        countryDisplayName: "",
        countrySortOrder: "",
      };
      tempCountryList.countryId = co.attribute_list_valid_value;
      tempCountryList.countryDisplayName = co.attribute_list_value_display_name;
      tempCountryList.countrySortOrder = co.attribute_list_value_sort_order;
      countryList.push(tempCountryList);
    }
  }

  templateTypeList.sort((a, b) => a.countrySortOrder - b.countrySortOrder);
  countryList.sort((a, b) => a.countrySortOrder - b.countrySortOrder);
  companyNameList.sort((a, b) => a.countrySortOrder - b.countrySortOrder);

  useEffect(() => {
    localStorage.setItem("formTemplateType", JSON.stringify(templateType));
    localStorage.setItem("formTemplateName", JSON.stringify(templateName));
    localStorage.setItem("formCompanyName", JSON.stringify(companyName));
    localStorage.setItem("formCountry", JSON.stringify(country));
  });

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };

  const handleSave = async (e) => {
    if (
      templateType.length < 1 ||
      templateName.length < 1 ||
      companyName.length < 1 ||
      country.length < 1
    ) {
      e.preventDefault();
      alert("Please fill all the Required Fields.!!!");
    } else {
      e.preventDefault();
      let template_id = await uuidv4();
      localStorage.setItem(
        "frontend_generated_template_id",
        JSON.stringify(template_id),
      );
      console.log(
        "id",
        JSON.parse(localStorage.getItem("frontend_generated_template_id")),
      );
      navigate("/integrate/createtemplates2");
      // window.location.reload();
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {" "}
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;}
            .required:after{content: "*"; color: red;}`}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar />
        <div className="2xl:ml-[14.5rem] ml-12">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/integrate" className="px-2">
              Integrate
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/createtemplates1">
              Create Template
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/createtemplates1">
              Set Template Properties
            </Link>
          </div>

          {/* page Heading*/}
          <div>
            <section className="flex items-center">
              <BsBoxArrowInRight style={iconStyles} />
              <p className="text-3xl mx-1">Create Template</p>
              <p className="text-[#0000A3] mt-2">info</p>
            </section>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>

          <div className="flex">
            {/*steps*/}
            <div className="ml-3">
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 1</p>
                <p className="font-bold text-md pb-1 ">
                  Set Template Properties
                </p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 2</p>
                <p className=" text-md pb-1 ">Capture Addtional Fields</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 3</p>
                <p className="text-md pb-1 ">Map Columns</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 4</p>
                <p className="text-md pb-1">Map Keys To Values</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
            </div>

            {/* form*/}
            <div className="mt-4 ml-6 w-[755px]">
              <Paper elevation={2}>
                <form className=" pt-8 grid grid-cols-2">
                  <div className="flex flex-col mx-14 my-3">
                    <div className=" w-full relative inline-block">
                      <FormControl
                        name="fullWidth1"
                        fullWidth
                        error={templateType.length > 0 ? false : true}
                      >
                        <InputLabel id="cn13">Template Type</InputLabel>
                        <Select
                          labelId="cn5"
                          id="cn30"
                          value={templateType}
                          label="Template Type"
                          onChange={handleTemplateTypeChange}
                          className={templateType.length > 0 ? "fill" : "empty"}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 430 } } }}
                        >
                          {templateTypeList.map((name, key) => (
                            <MenuItem key={key} value={name.templateTypeId}>
                              {name.templateTypeDisplayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="flex flex-col mx-14 my-3">
                    <div className="w-full relative inline-block">
                      <TextField
                        name="fullWidth1"
                        fullWidth
                        value={templateName}
                        onChange={handleTemplateNameChange}
                        id="templateName"
                        label="Template Name"
                        variant="outlined"
                        error={templateName.length > 0 ? false : true}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col mx-14 my-3">
                    <div className=" w-full relative inline-block">
                      <FormControl
                        name="fullWidth1"
                        fullWidth
                        error={companyName.length > 0 ? false : true}
                      >
                        <InputLabel id="cn13">Company Name</InputLabel>
                        <Select
                          labelId="cn5"
                          id="cn30"
                          value={companyName}
                          label="Company Name"
                          onChange={handleCompanyNameChange}
                          className={companyName.length > 0 ? "fill" : "empty"}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 430 } } }}
                        >
                          {companyNameList.map((name, key) => (
                            <MenuItem key={key} value={name.companyNameId}>
                              {name.companyNameDisplayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="flex flex-col mx-14 my-3">
                    <div className=" w-full relative inline-block">
                      <FormControl
                        name="fullWidth1"
                        fullWidth
                        error={country.length > 0 ? false : true}
                      >
                        <InputLabel id="cn13">Country</InputLabel>
                        <Select
                          labelId="cn5"
                          id="cn30"
                          value={country}
                          label="Country"
                          onChange={handleCountryChange}
                          className={country.length > 0 ? "fill" : "empty"}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 430 } } }}
                        >
                          {countryList.map((name, key) => (
                            <MenuItem key={key} value={name.countryId}>
                              {name.countryDisplayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="h-1" />
                  <div className="pr-14 pb-4 pt-16 w-full flex justify-end items-center">
                    <button
                      type="submit"
                      className="ml-5 bg-[#DB2748] py-[6px]  px-6  text-white  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] "
                      onClick={(e) => {
                        handleSave(e);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </form>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

          // <div className="pt-10">
          //   {" "}
          //   {JSON.stringify(
          //     [templateType, templateName, companyName, country],
          //     null,
          //     "    ",
          //   )}
          // </div>

// <button
//   onClick={(e) => {
//     handleClear(e);
//   }}
//   className="ml-10  text-gray-500"
// >
//   Cancel
// </button>
// <button
//   type="submit"
//   className="ml-10 bg-[#AEB3B7] py-[6px]  px-6  text-white"
//   disabled={true}
// >
//   Back
// </button>

// <Select
//           labelId="demo-multiple-chip-label"
//           id="demo-multiple-chip"
//           multiple
//           value={sheetName}
//           onChange={handleChange}
//           input={<OutlinedInput id="select-multiple-chip" label="" />}
//           renderValue={(selected) => (
//             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//               {selected.map((value) => (
//                 <Chip key={value} label={value} />
//               ))}
//             </Box>
//           )}
//           MenuProps={MenuProps}
//         >
//           {sheetNameList.map((name) => (
//             <MenuItem
//               key={name}
//               value={name}
//               style={getStyles(name, sheetName, theme)}
//             >
//               {name}
//             </MenuItem>
//           ))}
//         </Select>
//                           <div className=" w-full relative inline-block">
//                         <input
//                           list="sName"
//                           onChange={(e) => {
//                             onChange(e);
//                           }}
//                           spellCheck="off"
//                           className="border border-gray-300 px-2 py-[2px] z-20 w-full"
//                           id="sheetName"
//                           name="sheetName"
//                           value={template.sheetName}
//                         />
//                         <AiOutlineCaretDown style={listStyles} />
//                       </div>
//                       <datalist id="sName" className="bg-white p-5">
//                         {sheetNameList.map((option, i) => (
//                           <option className="bg-white p-4" key={i}>
//                             {option}
//                           </option>
//                         ))}
//                       </datalist>
//
// <select
//   id="templateName"
//   name="templateName"
//   value={template.templateName}
//   onChange={(e) => {
//     onChange(e);
//   }}
//   style={{ appearance: "none" }}
//   className="border bg-white border-gray-300 px-2 h-[30px] py-[2px] z-20 w-full"
// >
//   <option value=""></option>
//   {templateTypeList.map((option, i) => (
//     <option
//       value={option.attribute_list_valid_value}
//       className="bg-white p-4"
//       key={i}
//     >
//       {option.attribute_list_value_display_name}
//     </option>
//   ))}
// </select>
