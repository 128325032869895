import { React, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CContext } from "../../context/CompanyContext.jsx";
import { BsBoxArrowInRight } from "react-icons/bs";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import Paper from "@mui/material/Paper";
import Alerts from "../../components/Alerts.jsx";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries.js";
import * as mutations from "../../graphql/mutations.js";

export default function CreateCompanyPage({ user, signOut }) {
  //api
  const [wait, setWait] = useState(true);
  const [organization, setOrganization] = useState("");
  const [orgList, setOrgList] = useState([]);
  const [companyName, setcompanyName] = useState("");
  const [industry, setindustry] = useState("");
  const [subIndustry, setsubIndustry] = useState("");
  const [hqCountry, sethqCountry] = useState("");
  const [hqState, sethqState] = useState("");
  const [contactName, setcontactName] = useState("");
  const [contactRole, setcontactRole] = useState("");
  const [contactEmail, setcontactEmail] = useState("");
  // const [alert, setAlert] = useState("");
  const [userMadeAlert, setUserMadeAlert] = useState("");
  const [dateFormat, setdateFormat] = useState("");
  const [currencyPreference, setcurrencyPreference] = useState("");
  const [headcountValue, setHeadcountValue] = useState("");
  let industryList = [];
  let subIndustryList = [];
  let countryList = [];
  let stateList = [];

  useEffect(() => {
    const organizationDropDown = async () => {
      const orgDD = await API.graphql({
        query: mutations.OrganizationUserIdFilter,
        variables: { userid: user },
      });
      setOrgList(orgDD.data.OrganizationUserIdFilter);
      setWait(false);
    };

    organizationDropDown();

    return () => {};
  }, []);

  const {
    industryAndSubIndustryList,
    countryAndState,
    dateformatList,
    currencyList,
    headcount,
    createCompany,
  } = CContext();

  const handleOrgnizationNameChange = (event) => {
    setOrganization(event.target.value);
  };
  const handleCompanyNameChange = (event) => {
    setcompanyName(event.target.value);
  };
  const handleIndustryChange = (event) => {
    setindustry(event.target.value);
  };
  const handleSubIndustryChange = (event) => {
    setsubIndustry(event.target.value);
  };
  const handleHqCountryChange = (event) => {
    sethqCountry(event.target.value);
  };
  const handleHqStateChange = (event) => {
    sethqState(event.target.value);
  };
  const handleContactNameChange = (event) => {
    setcontactName(event.target.value);
  };
  const handleContactRoleChange = (event) => {
    setcontactRole(event.target.value);
  };
  const handleContactEmailChange = (event) => {
    setcontactEmail(event.target.value);
  };
  const handleDateFormatChange = (event) => {
    setdateFormat(event.target.value);
  };
  const handleCurrencyPreferenceChange = (event) => {
    setcurrencyPreference(event.target.value);
  };
  const handleHeadcountChange = (event) => {
    setHeadcountValue(event.target.value);
  };

  // const [action, setAction] = useState(false);
  //
  // let actionRef = useRef();
  // useEffect(() => {
  //   let handler = (e) => {
  //     if (!actionRef.current.contains(e.target)) {
  //       setAction(false);
  //     }
  //   };
  //
  //   document.addEventListener("mousedown", handler);
  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // });

  let duplicateIndustryList = [];
  for (var bp of industryAndSubIndustryList) {
    let tempIL = {
      key: "",
      displayName: "",
      sortOrder: "",
    };
    tempIL.key = bp.industry_key;
    tempIL.displayName = bp.industry_type_display_name;
    tempIL.sortOrder = bp.industry_sort_order;
    duplicateIndustryList.push(tempIL);
  }

  const uniqueMapCid = new Map();
  for (const obj of duplicateIndustryList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMapCid.has(objString)) {
      uniqueMapCid.set(objString, true);
      industryList.push(obj);
    }
  }

  let duplicateSubIndustryList = [];
  for (var si of industryAndSubIndustryList) {
    if (industry === si.industry_key) {
      let tempIL = {
        key: "",
        displayName: "",
        sortOrder: "",
      };
      tempIL.key = si.sub_industry_key;
      tempIL.displayName = si.sub_industry_type_display_name;
      tempIL.sortOrder = si.sub_industry_sort_order;
      duplicateSubIndustryList.push(tempIL);
    }
  }

  const uniqueMapCid1 = new Map();
  for (const obj of duplicateSubIndustryList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMapCid1.has(objString)) {
      uniqueMapCid1.set(objString, true);
      subIndustryList.push(obj);
    }
  }

  let duplicateCountryList = [];
  for (var c of countryAndState) {
    let tempIL = {
      key: "",
      displayName: "",
      sortOrder: "",
    };
    tempIL.key = c.country_code;
    tempIL.displayName = c.country_display_name;
    tempIL.sortOrder = c.country_sort_order;
    duplicateCountryList.push(tempIL);
  }

  const uniqueMapCid2 = new Map();
  for (const obj of duplicateCountryList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMapCid2.has(objString)) {
      uniqueMapCid2.set(objString, true);
      countryList.push(obj);
    }
  }

  let duplicateStateList = [];
  for (var s of countryAndState) {
    if (hqCountry === s.country_code) {
      let tempIL = {
        key: "",
        displayName: "",
        sortOrder: "",
      };
      tempIL.key = s.state_code;
      tempIL.displayName = s.state_display_name;
      tempIL.sortOrder = s.state_sort_order;
      duplicateStateList.push(tempIL);
    }
  }

  const uniqueMapCid3 = new Map();
  for (const obj of duplicateStateList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMapCid3.has(objString)) {
      uniqueMapCid3.set(objString, true);
      stateList.push(obj);
    }
  }

  let headcountList = [];
  for (var h of headcount) {
    if (h.attribute_key_name === "employer_size") {
      let tempIL = {
        key: "",
        displayName: "",
        sortOrder: "",
      };
      tempIL.key = h.attribute_list_valid_value;
      tempIL.displayName = h.attribute_list_value_display_name;
      tempIL.sortOrder = h.attribute_list_value_sort_order;
      headcountList.push(tempIL);
    }
  }

  const [saveAlert, setSaveAlert] = useState(false);

  let navigate = useNavigate();
  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };
  let alertStyles = { padding: "4px", color: "#365E3D", fontSize: "35px" };

  const handleSave = async (e) => {
    if (
      companyName.length < 1 ||
      industry.length < 1 ||
      subIndustry.length < 1 ||
      hqCountry.length < 1 ||
      contactName.length < 1 ||
      contactRole.length < 1 ||
      contactEmail.length < 1 ||
      organization.length < 1 ||
      headcountValue.length < 1
    ) {
      e.preventDefault();
      alert("Please fill all the Required Fields.!!!");
    } else {
      e.preventDefault();

      // company_country_hq,
      // company_id_status,
      // company_name,
      // company_state_hq,
      // industry_id,
      // is_exclude_from_benchmarks,
      // poc_email,
      // poc_role,
      // employer_size,
      // organization_id,
      // primary_poc,
      // sub_industry_id,
      // userid,
      //
      // company_id_status, y
      // company_name,
      // company_country_hq,
      // company_state_hq,
      // industry_id,
      // sub_industry_id,
      // is_exclude_from_benchmarks,y
      // primary_poc,
      // poc_role,
      // poc_email,
      // preferred_report_currency_code,
      // preferred_date_format,
      // userid,
      await createCompany(
        hqCountry,
        "y",
        companyName,
        hqState,
        industry,
        "y",
        contactEmail,
        contactRole,
        headcountValue,
        organization,
        contactName,
        subIndustry,
        user,
      );
      // alert("Success");
      setUserMadeAlert("success");
      setSaveAlert(true);
      navigate("/company/manage-company");
    }
  };

  const handleClear = async (e) => {
    e.preventDefault();
  };

  return (
    <>
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;}
            .required:after{content: "*"; color: red;}`}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar />
        <div className=" xl:ml-[3rem] 2xl:ml-[15rem] h-fit w-[855px] ">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/company" className="px-2">
              Company
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/company/create-company">
              Company Profile
            </Link>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>

          {/* Page and Action Buttons */}
          <div className="flex justify-between mt-4">
            <section className="flex items-center">
              <BsBoxArrowInRight style={iconStyles} />
              <p className="text-3xl mx-1">Company Profile</p>
              <p className="text-[#0000A3] mt-2 text-sm">info</p>
            </section>
            {userMadeAlert === "success" ? (
              <Alerts
                severity="success"
                info="Success! Your request has been processed"
              />
            ) : userMadeAlert === "error" ? (
              <Alerts severity="error" info="Try again later" />
            ) : (
              ""
            )}
          </div>
          <div className="my-4">
            <Paper elevation={2}>
              <form className="grid grid-cols-2 pt-8">
                <div className="flex flex-col mx-14 my-6">
                  <div className=" w-full relative inline-block">
                    <FormControl
                      name="fullWidth1"
                      fullWidth
                      // error={industry.length > 0 ? false : true}
                    >
                      <InputLabel id="cn13">Organization Name</InputLabel>
                      <Select
                        labelId="cn5"
                        id="cn30"
                        value={organization}
                        label="Organization Name"
                        onChange={handleOrgnizationNameChange}
                        className={organization.length > 0 ? "fill" : "empty"}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 430 } } }}
                      >
                        {orgList.map((name, key) => (
                          <MenuItem key={key} value={name.organization_id}>
                            {name.organization_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="flex flex-col mx-14 my-6">
                  <div className="w-full relative inline-block">
                    <TextField
                      name="fullWidth1"
                      fullWidth
                      value={companyName}
                      onChange={handleCompanyNameChange}
                      id="companyName"
                      label="Company Name"
                      variant="outlined"
                      // error={companyName.length > 0 ? false : true}
                    />
                  </div>
                </div>

                <div className="flex flex-col mx-14 my-6">
                  <div className=" w-full relative inline-block">
                    <FormControl
                      name="fullWidth1"
                      fullWidth
                      // error={industry.length > 0 ? false : true}
                    >
                      <InputLabel id="cn13">Industry</InputLabel>
                      <Select
                        labelId="cn5"
                        id="cn30"
                        value={industry}
                        label="Industry"
                        onChange={handleIndustryChange}
                        className={industry.length > 0 ? "fill" : "empty"}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 430 } } }}
                      >
                        {industryList.map((name, key) => (
                          <MenuItem key={key} value={name.key}>
                            {name.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="flex flex-col mx-14 my-6">
                  <div className=" w-full relative inline-block">
                    <FormControl
                      name="fullWidth1"
                      fullWidth
                      // error={subIndustry.length > 0 ? false : true}
                    >
                      <InputLabel id="cn13">Sub-Industry</InputLabel>
                      <Select
                        labelId="cn6"
                        id="cn31"
                        value={subIndustry}
                        label="Sub-Industry"
                        onChange={handleSubIndustryChange}
                        className={subIndustry.length > 0 ? "fill" : "empty"}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                      >
                        {subIndustryList.map((name, key) => (
                          <MenuItem key={key} value={name.key}>
                            {name.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="flex flex-col mx-14 my-6">
                  <div className=" w-full relative inline-block">
                    <FormControl
                      name="fullWidth1"
                      fullWidth
                      // error={hqCountry.length > 0 ? false : true}
                    >
                      <InputLabel id="cn13">HQ Country</InputLabel>
                      <Select
                        labelId="cn7"
                        id="cn32"
                        value={hqCountry}
                        label="HQ Country"
                        onChange={handleHqCountryChange}
                        className={hqCountry.length > 0 ? "fill" : "empty"}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                      >
                        {countryList.map((name, key) => (
                          <MenuItem key={key} value={name.key}>
                            {name.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="flex flex-col mx-14 my-6">
                  <div className=" w-full relative inline-block">
                    <FormControl
                      name="fullWidth1"
                      fullWidth
                      // error={hqState.length > 0 ? false : true}
                    >
                      <InputLabel id="cn13">HQ State</InputLabel>
                      <Select
                        labelId="cn8"
                        id="cn33"
                        value={hqState}
                        label="HQ State"
                        onChange={handleHqStateChange}
                        className={hqState.length > 0 ? "fill" : "empty"}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                      >
                        {stateList.map((name, key) => (
                          <MenuItem key={key} value={name.key}>
                            {name.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="flex flex-col mx-14 my-6">
                  <div className="w-full relative inline-block">
                    <TextField
                      name="fullWidth1"
                      fullWidth
                      value={contactName}
                      onChange={handleContactNameChange}
                      id="contactName"
                      label="Contact Name"
                      variant="outlined"
                      // error={contactName.length > 0 ? false : true}
                    />
                  </div>
                </div>

                <div className="flex flex-col mx-14 my-6">
                  <div className="w-full relative inline-block">
                    <TextField
                      name="fullWidth1"
                      fullWidth
                      value={contactRole}
                      onChange={handleContactRoleChange}
                      id="contactRole"
                      label="Contact Role"
                      variant="outlined"
                      // error={contactRole.length > 0 ? false : true}
                    />
                  </div>
                </div>

                <div className="flex flex-col mx-14 my-6">
                  <div className="w-full relative inline-block">
                    <TextField
                      name="fullWidth1"
                      fullWidth
                      value={contactEmail}
                      onChange={handleContactEmailChange}
                      id="contactEmail"
                      label="Contact Email"
                      variant="outlined"
                      // error={contactEmail.length > 0 ? false : true}
                    />
                  </div>
                </div>

                <div className="flex flex-col mx-14 my-6">
                  <div className=" w-full relative inline-block">
                    <FormControl
                      name="fullWidth1"
                      fullWidth
                      // error={dateFormat.length > 0 ? false : true}
                    >
                      <InputLabel id="cn13">Headcount</InputLabel>
                      <Select
                        labelId="cn9"
                        id="cn34"
                        value={headcountValue}
                        label="Headcount"
                        onChange={handleHeadcountChange}
                        className={headcountValue.length > 0 ? "fill" : "empty"}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                      >
                        {headcountList.map((name, key) => (
                          <MenuItem key={key} value={name.key}>
                            {name.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="w-[315px]" />
                <div className="pb-4 pr-4 w-full pt-10 flex justify-end items-center">
                  <button
                    onClick={(e) => {
                      handleClear(e);
                    }}
                    className="ml-10 text-lg text-gray-500  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] px-6 py-[6px]"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="ml-10 bg-[#0000A3] py-[6px] text-lg px-6  text-white  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] "
                    onClick={(e) => {
                      handleSave(e);
                    }}
                  >
                    Save
                  </button>
                </div>
              </form>
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
}

// <div className="pt-10">
//   {" "}
//   {JSON.stringify(
//     [
//       organization,
//       companyName,
//       industry,
//       subIndustry,
//       hqCountry,
//       hqState,
//       contactName,
//       contactRole,
//       contactEmail,
//       headcountValue,
//     ],
//     null,
//     "    ",
//   )}
// </div>

// <div className="flex flex-col mx-14 my-6">
//   <div className=" w-full relative inline-block">
//     <FormControl
//       name="fullWidth1"
//       fullWidth
//       // error={currencyPreference.length > 0 ? false : true}
//     >
//       <InputLabel id="cn13">Currency Preference</InputLabel>
//       <Select
//         labelId="cn10"
//         id="cn35"
//         value={currencyPreference}
//         label="Currency Preference"
//         onChange={handleCurrencyPreferenceChange}
//         className={
//           currencyPreference.length > 0 ? "fill" : "empty"
//         }
//         MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
//       >
//         {currencyList.map((name, key) => (
//           <MenuItem
//             key={key}
//             value={name.attribute_list_valid_value}
//           >
//             {name.attribute_list_value_display_name}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   </div>
// </div>

// setCompanyProfile({
//   companyName: "",
//   industry: "",
//   subIndustry: "",
//   hqCountry: "",
//   hqState: "",
//   contactName: "",
//   contactRole: "",
//   contactEmail: "",
//   dateFormat: "",
//   currencyPreference: "",
// });
//
//
// <div className=" w-full relative inline-block">
//   <input
//     list="i"
//     autoComplete="off"
//     spellCheck="false"
//     className="border border-gray-300 px-2 py-[2px] z-20 w-full"
//     id="industry"
//     name="industry"
//     value={companyProfile.industry}
//   />
//   <AiOutlineCaretDown style={listStyles} />
// </div>
// <datalist id="i" className="bg-white p-5">
//   {industryList.map((option, i) => (
//     <option
//       value={option.attribute_list_value_display_name}
//       className="bg-white p-4"
//       key={i}
//     >
//       {option.attribute_list_value_display_name}
//     </option>
//   ))}
// </datalist> */}
//
{
  /*
                                                          <div className="flex justify-end mt-1">
                                                            <section ref={actionRef}>
                                                              <div className="flex w-full justify-center items-center">
                                                                <div className="flex justify-center items-center">
                                                                  <div
                                                                    onClick={() => setAction((prev) => !prev)}
                                                                    className="hover:cursor-pointer px-[10px] py-2 text-md border border-gray-400 bg-gray-100 flex items-center"
                                                                  >
                                                                    Actions
                                                                    <div className="pl-2">
                                                                      <AiOutlineCaretDown />
                                                                    </div>
                                                                  </div>
                                                                  {action && (
                                                                    <div className="hover:cursor-pointer absolute mt-[9rem] ml-1 flex z-[1] flex-col justify-center w-[98.4667px] h-fit bg-white shadow-lg py-4 ">
                                                                      <p className=" capitalize text-md hover:bg-gray-200 font-medium text-center">
                                                                        Update
                                                                      </p>
                                                                      <button className=" capitalize text-md hover:bg-gray-200 font-medium text-center">
                                                                        delete
                                                                      </button>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                */
}
