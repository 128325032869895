// React Router Dom
import { Route, Routes, Link, useNavigate } from "react-router-dom";
import { React, useState } from "react";

// Context Provider
import { CContext, CompanyContextProvider } from "./context/CompanyContext.jsx";
import { IntegrateContextProvider } from "./context/IntegrateContext.jsx";

// Pages
import {
  CreateCompanyPage,
  LandingPage,
  ManageCompaniesPage,
  ManagePermissionsPage,
  CreateUserPage,
} from "./pages/companypages/index.jsx";

import { DashboardViewPage } from "./pages/dashboardpages/index.jsx";

import {
  IntegrateLandingPage,
  CreateTemplateS1,
  CreateTemplateS2,
  CreateTemplateS3,
  CreateTemplateS4,
  ManageIntegrateTemplate,
  UploadTemplateS1,
  UploadTemplateS2,
  UploadTemplateS3,
  IntegrateUploadSelect,
  IntegrateSelection,
  EFormUploadS1,
  EFormUploadS2,
  ManageFiles,
} from "./pages/integratepages/index.jsx";

import {
  ReportLandingPage,
  ReconcilePage,
  ReconcileInstructionsPage,
  ManageReconciliations,
  ReconcileSelect,
  RunReport,
  StandardReportSelect,
  ManageReports,
} from "./pages/reportpages/index.jsx";

import {
  DesignLandingPage,
  CreateBenefitS1,
  CreateBenefitS2,
  BenefitInventory,
} from "./pages/designpages/index.jsx";

import Login from "./pages/Login.jsx";
import InDev from "./pages/InDev.jsx";
import NotFound from "./pages/NotFound.jsx";

// Materia UI Context
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// Amplify
// import withAuthentication from './withAuthentication.js';
// import { Authenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { Amplify } from "aws-amplify";
import { API } from "aws-amplify";
import * as queries from "../src/graphql/queries.js";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);
API.configure(awsExports);

// API calls
export default function App() {
  let navigate = useNavigate();

  const [userState, setUserState] = useState(localStorage.getItem("userState"));

  const [username, setUserName] = useState(
    localStorage.getItem("loggedInUser"),
  );

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUserState(false);
      localStorage.removeItem("loggedInUser");
      localStorage.removeItem("userState");
      navigate("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const getUserDetails = async (username, userState) => {
    setUserName(username);
    setUserState(userState);
  };

  return (
    <>
      <style>{`input[type="checkbox"]{accent-color:#0000A3;}`}</style>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <IntegrateContextProvider>
          <CompanyContextProvider>
            <Routes>
              <Route
                exact
                path="/"
                element={<Login getUserDetails={getUserDetails} />}
              />
              {userState ? (
                <>
                  <Route
                    exact
                    path="/company"
                    element={<LandingPage user={username} signOut={signOut} />}
                  />
                  <Route
                    path="/company/create-company"
                    element={
                      <CreateCompanyPage user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/company/manage-company"
                    element={
                      <ManageCompaniesPage user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/company/manage-permissions"
                    element={
                      <ManagePermissionsPage
                        user={username}
                        signOut={signOut}
                      />
                    }
                  />
                  <Route
                    path="/company/create-user"
                    element={
                      <CreateUserPage user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate"
                    element={
                      <IntegrateLandingPage user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/select"
                    element={
                      <IntegrateSelection user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/manage-template"
                    element={
                      <ManageIntegrateTemplate
                        user={username}
                        signOut={signOut}
                      />
                    }
                  />
                  <Route
                    path="/integrate/manage-files"
                    element={<ManageFiles user={username} signOut={signOut} />}
                  />
                  <Route
                    path="/integrate/upload-select"
                    element={
                      <IntegrateUploadSelect
                        user={username}
                        signOut={signOut}
                      />
                    }
                  />
                  <Route
                    path="/integrate/eformuploads1"
                    element={
                      <EFormUploadS1 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/eformuploads2"
                    element={
                      <EFormUploadS2 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/uploadtemplates1"
                    element={
                      <UploadTemplateS1 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/uploadtemplates2"
                    element={
                      <UploadTemplateS2 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/uploadtemplates3"
                    element={
                      <UploadTemplateS3 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/createtemplates1"
                    element={
                      <CreateTemplateS1 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/createtemplates2"
                    element={
                      <CreateTemplateS2 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/createtemplates3"
                    element={
                      <CreateTemplateS3 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/createtemplates4"
                    element={
                      <CreateTemplateS4 user={username} signOut={signOut} />
                    }
                  />
                  {/*
                  <Route
                    path="/report"
                    element={
                      <ReportLandingPage user={username} signOut={signOut} />
                    }
// /reconcile-select
                  ///standardreport-select
                  />
                  <Route
                    path="/report/reconcile-select"
                    element={
                      <ReconcileSelect user={username} signOut={signOut} />
                    }
                  />
*/}
                  <Route
                    path="/report"
                    element={
                      <StandardReportSelect user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/report/reconcile1"
                    element={
                      <ReconcileInstructionsPage
                        user={username}
                        signOut={signOut}
                      />
                    }
                  />
                  <Route
                    path="/report/reconcile2"
                    element={
                      <ReconcilePage user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/report/manage-reconciliations"
                    element={
                      <ManageReconciliations
                        user={username}
                        signOut={signOut}
                      />
                    }
                  />
                  <Route
                    path="/report/manage-reports"
                    element={
                      <ManageReports user={username} signOut={signOut} />
                    }
                  />

                  <Route
                    path="/report/runreport"
                    element={<RunReport user={username} signOut={signOut} />}
                  />
                  <Route
                    path="/design"
                    element={
                      <DesignLandingPage user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/design/createbenefits1"
                    element={
                      <CreateBenefitS1 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/design/createbenefits2"
                    element={
                      <CreateBenefitS2 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/design/benefitinventory"
                    element={
                      <BenefitInventory user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/in-development"
                    element={<InDev user={username} signOut={signOut} />}
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <DashboardViewPage user={username} signOut={signOut} />
                    }
                  />
                </>
              ) : (
                <Route path="*" element={<NotFound />} />
              )}
            </Routes>
            {userState && (
              <>
                <div className="pt-1" style={{ height: "5%" }}>
                  <hr className="bg-black h-[1.5px]" />
                  <footer className="w-full mt-2 flex justify-between py-2 text-[#0000A3]">
                    <p className="ml-4 w-1/3 flex justify-start items-center">
                      Feedback
                    </p>
                    <p className="w-1/3 flex justify-center items-center">
                      © 2023, Acropolis Analytics.
                    </p>
                    <div className="flex mr-4 w-1/3 justify-end items-center cursor-pointer">
                      <Link
                        target="_blank"
                        to="https://d63lwo9una5sa.cloudfront.net/Service%20Agreement%20and%20Terms%20of%20Use.pdf"
                        className="ml-4"
                      >
                        Terms
                      </Link>
                      <Link
                        target="_blank"
                        to="https://d63lwo9una5sa.cloudfront.net/Privacy%20Policy.pdf"
                        className="ml-4"
                      >
                        Privacy
                      </Link>
                      <Link
                        target="_blank"
                        to="https://d63lwo9una5sa.cloudfront.net/cookie%20notice.pdf"
                        className="ml-4"
                      >
                        Cookies
                      </Link>
                    </div>
                  </footer>
                </div>
              </>
            )}
          </CompanyContextProvider>
        </IntegrateContextProvider>
      </LocalizationProvider>
    </>
  );
}





// report page reconfig
//
                  // <Route
                  //   path="/report"
                  //   element={
                  //     <ReportLandingPage user={username} signOut={signOut} />
                  //   }
                  // />
                  // <Route
                  //   path="/report/reconcile-select"
                  //   element={
                  //     <ReconcileSelect user={username} signOut={signOut} />
                  //   }
                  // />
                  // <Route
                  //   path="/report/standardreport-select"
                  //   element={
                  //     <StandardReportSelect user={username} signOut={signOut} />
                  //   }
                  // />
                  //


// <Authenticator>
//   {({ signOut, user }) => (
//       <>
//       {
//       console.log(user===null?'no':'yes')
//       }
//       {
//       console.log(currentUserInfo())
//        }
//       <button onClick={signOut}>singOut</button>
//       </>
//   )}
// </Authenticator>

//     </div>
//   </div>
//   <div className="flex">
//     <div className="h-fit">
//       <Sidebar />
//     </div>
//     <div className="w-full h-fit">
//         <Route path="/junk" element={<Test/>} />
//   <Link className="text-xl px-4 hover:border-b-2 border-[#DB2748] py-2" to="/">Company</Link>
//   <Link className="text-xl px-4 hover:border-b-2 border-[#DB2748] py-2" to="/design">Design</Link>
//   <Link className="text-xl px-4 hover:border-b-2 border-[#DB2748] py-2" to="/integrate">Integrate</Link>
//   <Link className="text-xl px-4 hover:border-b-2 border-[#DB2748] py-2" to="/report">Report</Link>
//   <Link className="text-xl px-4 hover:border-b-2 border-[#DB2748] py-2" to="/dashboard">Dashboard</Link>
//   <Link className="text-xl px-4 hover:border-b-2 border-[#DB2748] py-2" to="/communicate">Communicate</Link>
// <Routes>
// <Route exact path="/" element={} />
// <Route path="/about" element={} />
// </Routes>
//   <h1>Hello {username}</h1>
//   <button onClick={signOut}>Sign out</button>
//
