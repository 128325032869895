import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import LinkCard from "../../components/LinkCard.jsx";
import {
  MdOutlineChecklist,
  MdTune,
  MdUploadFile,
  MdDynamicForm,
} from "react-icons/md";
import {
  BsBell,
  BsBoxArrowInRight,
  BsBoxes,
  BsEject,
  BsClock,
} from "react-icons/bs";
import Paper from "@mui/material/Paper";

export default function IntegrateUploadSelect({ user, signOut }) {
  let iconStyles = { color: "#DB2748", fontSize: "60px" };

  return (
    <>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar />
        <div className=" pt-24 md:mx-40 md:mr-[25rem] 2xl:mr-[40rem] 2xl:ml-[25rem] ">
          <Paper className="mt-6 pb-14 pl-1" elevation={2}>
          <div className="w-[584px] h-[558px] grid grid-cols-2 justify-center items-center">
            <Link to="/integrate/uploadtemplates1">
              <LinkCard
                icon={<BsEject style={iconStyles} />}
                linkText="Upload File"
              />
            </Link>
            <Link to="/integrate/eformuploads1">
              <LinkCard
                icon={<MdOutlineChecklist style={iconStyles} />}
                linkText="E-Form Upload"
              />
            </Link>
            <Link to="/in-development" className="w-full col-span-2" hidden>
              <div className="flex justify-center">
                <LinkCard
                  icon={<BsClock style={iconStyles} />}
                  linkText="Schedule"
                />
              </div>
            </Link>
          </div>
          </Paper>
          <p className="pt-12 w-[584px] h-fit text-center text-gray-400">
            To complete an integration, you must first create a reporting
            template. <br />
            Once done,you can either manually upload data to this reporting
            template <br /> or schedule an automated refresh with the source
            system.
          </p>
        </div>
      </div>
    </>
  );
}
