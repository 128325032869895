import { React } from "react";
import LinkCard from "../../components/LinkCard.jsx";
import { BsSpeedometer2, BsBoxes, BsEject } from "react-icons/bs";
import { MdOutlineAutoFixHigh, MdTrackChanges } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import Paper from "@mui/material/Paper";
import DesignLandingPageImage from "../../assets/Design Page.jpg";

export default function DesignLandingPage({ profiles, user, signOut }) {
  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "60px" };

  return (
    <>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
        <Sidebar />
        <div className="pt-20 h-fit xl:mx-28 xl:mr-[25rem] 2xl:mr-[40rem] 2xl:ml-[15rem] ">
          <Paper className="" elevation={2}>
            <div className="text-center font-bold uppercase text-2xl py-2">
              Design
            </div>
            <div className="mt-6  flex justify-center items-center">
              <div className="w-[584px] h-[300px] grid grid-cols-2 justify-center items-center">
                <Link to="/design/createbenefits1">
                  <LinkCard
                    icon={<MdOutlineAutoFixHigh style={iconStyles} />}
                    linkText="Create Benefit"
                  />
                </Link>
                <Link to="/design/benefitinventory">
                  <LinkCard
                    icon={<BsBoxes style={iconStyles} />}
                    linkText="Benefit Inventory"
                  />
                </Link>
                <Link to="/in-development" hidden>
                  <LinkCard
                    icon={<BsSpeedometer2 style={iconStyles} />}
                    linkText="Benchmarks"
                  />
                </Link>
                <Link to="/in-development" hidden>
                  <LinkCard
                    icon={<MdTrackChanges style={iconStyles} />}
                    linkText="Manage Benchmarks"
                  />
                </Link>
              </div>

              <div className="w-[400px] rounded-lg flex justify-center items-center 2xl:w-[584px] py-5 px-5">
                <img src={DesignLandingPageImage} alt="" />
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </>
  );
}
