import { BsBoxes } from "react-icons/bs";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { React, useState, useEffect, useRef } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { API } from "aws-amplify";
import * as mutations from "../../graphql/mutations.js";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function ManageIntegrateTemplate({ user, signOut }) {
  const [wait, setWait] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const manageTemplateData = async () => {
      const mTD = await API.graphql({
        query: mutations.IntegrateTemplateOverviewFilter,
        variables: { userid: user },
      });
      setRows(mTD.data.IntegrateTemplateOverviewFilter);
      setWait(false);
    };

    manageTemplateData();

    return () => {};
  }, []);
  console.log(rows);

  const columns = [
    {
      field: "template_id",
      renderHeader: () => <strong>{"Template ID"}</strong>,
      width: 130,
    },
    {
      field: "template_type_display_name",
      renderHeader: () => <strong>{"Template Type"}</strong>,
      width: 200,
    },
    {
      field: "template_display_name",
      renderHeader: () => <strong>{"Template Name"}</strong>,
      width: 200,
    },
    {
      field: "company_name",
      renderHeader: () => <strong>{"Company Name"}</strong>,
      width: 150,
    },
    {
      field: "region_name",
      renderHeader: () => <strong>{"Region"}</strong>,
      width: 100,
    },
    {
      field: "sub_region_name",
      renderHeader: () => <strong>{"Sub Region"}</strong>,
      width: 150,
    },
    {
      field: "country_name",
      renderHeader: () => <strong>{"Country"}</strong>,
      width: 150,
    },
    {
      field: "userid",
      renderHeader: () => <strong>{"Last Modified By"}</strong>,
      width: 150,
    },
    {
      field: "as_of_date",
      renderHeader: () => <strong>{"Last Updated Date"}</strong>,
      width: 150,
    },
  ];

  const [selectedRows, setSelectedRows] = useState([]);

  const handleDeleteRow = async () => {
    console.log(selectedRows);
    let newRows = rows.filter((row) => !selectedRows.includes(row.template_id));
    setRows(newRows);
    let delRows = rows.filter((row) => selectedRows.includes(row.template_id));
    let delapirows = delRows.map(row=>({template_id : row.template_id}));
    const deleteOperation = await API.graphql({
      query: mutations.deleteTemplateDetails,
      variables: {
        delete_template_details: delapirows,
        userid: user,
      },
    });
  };

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };

  const navigate = useNavigate();

  const [action, setAction] = useState(false);

  let actionRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!actionRef.current.contains(e.target)) {
        setAction(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler)
    };
  });

  return (
    <>
      {" "}
      <style>{`.css-1si6n65{overflow-y: hidden!important;} .css-frlfct{color:#9ca3af!important;} .css-y599qu{color:#6b7280!important;}`}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex w-full">
          <Sidebar />
        <div className=" xl:ml-[3rem] 2xl:ml-[15rem] h-fit w-[855px] ">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/integrate" className="px-2">
              Integrate
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/manage-template">
              Manage Template
            </Link>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>

          {/* page Heading*/}
          <div>
            <section className="flex items-center">
              <BsBoxes style={iconStyles} />
              <p className="text-3xl mx-1">Manage Template</p>
              <p className="text-[#0000A3] mt-2">info</p>
            </section>
          </div>

          <div className="flex items-center space-x-3 justify-end w-[1050px] mr-4">
            <div>
              <button
                className="ml-5 bg-[#0000A3]  mt-1 py-[8px] px-6 text-white"
                type="button"
                onClick={() => {
                  navigate("/integrate/createtemplates1");
                }}
              >
                New Template
              </button>
            </div>
            <div className="flex justify-end mt-1">
              <section ref={actionRef}>
                <div className="flex w-full justify-center items-center">
                  <div className="flex justify-center items-center">
                    <div
                      onClick={() => setAction((prev) => !prev)}
                      className="hover:cursor-pointer px-[10px] py-2 text-md border border-gray-400 bg-gray-100 flex items-center"
                    >
                      Actions
                      <div className="pl-2">
                        <AiOutlineCaretDown />
                      </div>
                    </div>
                    {action && (
                      <div className="hover:cursor-pointer absolute mt-[9rem] ml-1 space-y-1 flex z-[1] flex-col justify-center w-[98.4667px] h-fit bg-white shadow-lg py-4 ">
                        <p className=" hover:bg-gray-200 capitalize text-md font-medium text-center">
                          Update
                        </p>
                        <button
                          onClick={handleDeleteRow}
                          className="hover:bg-gray-200 capitalize text-md font-medium text-center"
                        >
                          delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div className="h-[450px] w-[1050px] pt-8 mr-4">
            <DataGrid
              style={{ overflowY: "scroll" }}
              rows={rows}
              getRowId={(row) => row.template_id}
              checkboxSelection
              columns={columns}
              onRowSelectionModelChange={(row) => setSelectedRows(row)}
              hideFooter
              hideFooterPagination
              hideFooterSelectedRowCount
            />
          </div>
        </div>
      </div>
    </>
  );
}

// sx={{color: "#bdc1c9"}}
// style={{ height: 400, width: "100%" }}
// console.log(rows.filter((row) => !selectedRows.includes(row.id)))
// console.log(rows.filter((row) => row.id === selectedRows.forEach((num) => num)))
// console.log(rows.filter((row) => selectedRows.filter((num) => num === row.id)))
// selectedRows.forEach((num) => console.log(num))
// console.log(rows.filter((row) => selectedRows.forEach((num) => num === row.id)));
// setRows(rows.filter((row) => selectedRows.filter((num) => num === row.id)))
// setRows(
//   rows.filter((r) => selectedRows.filter((dr) => dr.id === r.id).length < 1)
// );
// onSelectionChange={handleSelectionChange}
// onSelectionChange={(e) => setSelectedRows(e.rows)}
// onRowSelected={handleRowSelection}
// style={{
//   height: 400,
//   width: "fit-conten",
//   paddingTop: "20px",
// }}
//

  // const columns = [
  //   {
  //     field: "company",
  //     renderHeader: () => <strong>{"Company"}</strong>,
  //     width: 130,
  //   },
  //   {
  //     field: "templateType",
  //     renderHeader: () => <strong>{"Template Type"}</strong>,
  //     width: 150,
  //   },
  //   {
  //     field: "templatName",
  //     renderHeader: () => <strong>{"Template Name"}</strong>,
  //     width: 150,
  //   },
  //   {
  //     field: "country",
  //     renderHeader: () => <strong>{"Country"}</strong>,
  //     width: 130,
  //   },
  //   {
  //     field: "lastUpdatedBy",
  //     renderHeader: () => <strong>{"Last Updated By"}</strong>,
  //     width: 150,
  //   },
  //   {
  //     field: "lastModifedDate",
  //     renderHeader: () => <strong>{"Last Modifed Date"}</strong>,
  //     width: 150,
  //   },
  // ];
  //
  // const data1 = [
  //   {
  //     company: "Company1",
  //     templateType: "Template Type 1",
  //     templatName: "Template Name 1",
  //     country: "Country",
  //     lastUpdatedBy: "User 1",
  //     lastModifedDate: "Date 1",
  //     id: 1,
  //   },
  //   {
  //     company: "Company2",
  //     templateType: "Template Type 2",
  //     templatName: "Template Name 2",
  //     country: "Country",
  //     lastUpdatedBy: "User 2",
  //     lastModifedDate: "Date 2",
  //     id: 2,
  //   },
  //   {
  //     company: "Company3",
  //     templateType: "Template Type 3",
  //     templatName: "Template Name 3",
  //     country: "Country",
  //     lastUpdatedBy: "User 3",
  //     lastModifedDate: "Date 3",
  //     id: 3,
  //   },
  //   {
  //     company: "Company4",
  //     templateType: "Template Type 4",
  //     templatName: "Template Name 4",
  //     country: "Country",
  //     lastUpdatedBy: "User 4",
  //     lastModifedDate: "Date 4",
  //     id: 4,
  //   },
  //   {
  //     company: "Company5",
  //     templateType: "Template Type 5",
  //     templatName: "Template Name 5",
  //     country: "Country",
  //     lastUpdatedBy: "User 5",
  //     lastModifedDate: "Date 5",
  //     id: 5,
  //   },
  //   {
  //     company: "Company6",
  //     templateType: "Template Type 6",
  //     templatName: "Template Name 6",
  //     country: "Country",
  //     lastUpdatedBy: "User 6",
  //     lastModifedDate: "Date 6",
  //     id: 6,
  //   },
  //   {
  //     company: "Company7",
  //     templateType: "Template Type 7",
  //     templatName: "Template Name 7",
  //     country: "Country",
  //     lastUpdatedBy: "User 7",
  //     lastModifedDate: "Date 7",
  //     id: 7,
  //   },
  //   {
  //     company: "Company8",
  //     templateType: "Template Type 8",
  //     templatName: "Template Name 8",
  //     country: "Country",
  //     lastUpdatedBy: "User 8",
  //     lastModifedDate: "Date 8",
  //     id: 8,
  //   },
  //   {
  //     company: "Company9",
  //     templateType: "Template Type 9",
  //     templatName: "Template Name 9",
  //     country: "Country",
  //     lastUpdatedBy: "User 9",
  //     lastModifedDate: "Date 9",
  //     id: 9,
  //   },
  // ];
