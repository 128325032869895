import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsBoxes } from "react-icons/bs";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Alerts from "../../components/Alerts.jsx";
import { DataGrid, GridCellEditStopReasons } from "@mui/x-data-grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries.js";
import * as mutations from "../../graphql/mutations.js";

export default function ReconcilePage({ user, signOut }) {
  const [wait, setWait] = useState(true);
  const [alert, setAlert] = useState("");
  const editedRows = [];
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessCompleted, setIsProcessCompleted] = useState(false);
  const [getReconRequestId, setReconRequestId] = useState(undefined);
  const [getValidDataResponseIndicator, setValidDataResponseIndicator] =
    useState(false);
  const [tempRows, setTempRows] = useState([]);
  let backendRows = [];
  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };

  const getTableData = async () => {
    const tableData = await API.graphql({
      query: queries.readUnpublishedDemographicProviderReconDetails,
      variables: { request_id: getReconRequestId },
    }).then((data) => {
      console.log("Fetching Table Data");
      console.log(data);
      let data_array = data.data.readUnpublishedDemographicProviderReconDetails;
      console.log(data_array);
      let itr = 0;
      for (var item of data_array) {
        item.id = itr + 1;
        itr++;
      }
      setTempRows(data_array);
      console.log(data_array);
    });
    setIsLoading(false);
    setWait(false);
  };

  const getStoredProcStatus = async () => {
    if (getReconRequestId === undefined) {
      let req_id = localStorage.getItem("reconRequestId");
      setReconRequestId(req_id);
      console.log(getReconRequestId);
    }
    var getReportProcStatus = await API.graphql({
      query: queries.readReportReconProcLog,
      variables: { request_id: getReconRequestId },
    }).then((data) => {
      console.log(data);
      console.log("Valid Output Indicator : " + getValidDataResponseIndicator);
      console.log("Is Process Completed Indicator : " + isProcessCompleted);
      console.log("Is Loading Flag : " + isLoading);
      if (getReconRequestId === undefined) {
        setIsProcessCompleted(false);
      } else {
        console.log(
          "Process Status : " + data.data.readReportReconProcLog[0].status
        );
        if (data.data.readReportReconProcLog[0].status === "3. End") {
          setIsProcessCompleted(true);
          getTableData().then(setValidDataResponseIndicator(true));
        } else {
          setIsProcessCompleted(false);
        }
      }
    });
  };
  // let tempTemplateType1List = [];
  // for (var t of tempReconList) {
  //   if (t.recon_type === reconType.rtype) {
  //     let temp1 = {
  //       template_type_id: "",
  //       template_type_display_name: "",
  //     };
  //     temp1.template_type_id = t.template_type_id;
  //     temp1.template_type_display_name = t.template_type_display_name;
  //     tempTemplateType1List.push(temp1);
  //   } else {
  //   }
  // }

  useEffect(() => {
    setIsLoading(true);
    getStoredProcStatus();
    setIsLoading(false);
  }, []);

  if (isProcessCompleted === false) {
    getStoredProcStatus();
  } else if (
    isProcessCompleted === true &&
    getValidDataResponseIndicator === false
  ) {
    setAlert("error");
  }

  const columns = [
    { field: "recon_status", headerName: "Recon Status", width: 250 },
    {
      field: "administrator_comments",
      type: "string",
      headerName: "Admin Comments",
      width: 250,
      editable: true,
      cellClassName: (params) => {
        // params.value.length <= 0 || params.value.length === null
        if (params.value === null) {
          return "empty";
        }
        if (params.value.length > 0) {
          return "fill";
        }
        // console.log(params.value.length);
      },
    },
    {
      field: "recon_status_detail",
      headerName: "Recon Status Details",
      width: 150,
    },
    { field: "snap_dt", headerName: "Snap Date", width: 150 },
    {
      field: "emp_join_key_value",
      headerName: "Employee Join Key Value",
      width: 150,
    },
    {
      field: "provider_join_key_value",
      headerName: "Provider Join Key Value",
      width: 150,
    },
    { field: "country", headerName: "Country", width: 150 },
    { field: "emplid", headerName: "Employee Id", width: 150 },
    { field: "provider_emplid", headerName: "Provider Employ Id", width: 150 },
    { field: "ee_emplid", headerName: "EE Employee Id", width: 150 },
    {
      field: "provider_benefit_id",
      headerName: "Provider Benefit Id",
      width: 150,
    },
    {
      field: "provider_plan_display_name",
      headerName: "Provider Plan Display Name",
      width: 150,
    },
    {
      field: "provider_national_id",
      headerName: "Provider National Id",
      width: 150,
    },
    { field: "national_ids", headerName: "National Ids", width: 150 },
    {
      field: "provider_first_name",
      headerName: "Provider First Name",
      width: 150,
    },
    { field: "ee_first_name", headerName: "EE First Name", width: 150 },
    {
      field: "provider_middle_name",
      headerName: "Provider Middle Name",
      width: 150,
    },
    { field: "ee_middle_name", headerName: "EE Middle Name", width: 150 },
    {
      field: "provider_last_name",
      headerName: "Provider Last Name",
      width: 150,
    },
    { field: "ee_last_name", headerName: "EE Last Name", width: 150 },
    { field: "provider_date_of_birth", headerName: "Provider DOB", width: 150 },
    { field: "ee_date_of_birth", headerName: "EE DOB", width: 150 },
    {
      field: "provider_coverage_begin_date",
      headerName: "Provider Coverage Begin Date",
      width: 150,
    },
    {
      field: "provider_coverage_end_date",
      headerName: "Provider Coverage End Date",
      width: 150,
    },
    {
      field: "provider_policy_id",
      headerName: "Provider Policy Id",
      width: 150,
    },
    {
      field: "provider_vendor_name",
      headerName: "Provider Vendor Name",
      width: 150,
    },
    {
      field: "ee_recent_hire_date",
      headerName: "EE Recent Hire Date",
      width: 150,
    },
    {
      field: "ee_termination_date",
      headerName: "EE Termination Date",
      width: 150,
    },
    {
      field: "ee_country_entry_date",
      headerName: "EE Country Entry Date",
      width: 150,
    },
    {
      field: "ee_target_hr_status_code",
      headerName: "EE Target HR Status Code",
      width: 150,
    },
    {
      field: "ee_source_hr_status_code",
      headerName: "EE Source HR Status Code",
      width: 150,
    },
    { field: "ee_job_family", headerName: "EE Job Family", width: 150 },
    {
      field: "ee_target_employee_class",
      headerName: "EE Target Employee Class",
      width: 150,
    },
    {
      field: "ee_source_employee_class",
      headerName: "EE Source Employee Class",
      width: 150,
    },
  ];

  backendRows = tempRows;
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    editedRows.push(newRow);
    console.log(editedRows);
    let unEditedRows = backendRows.filter((row) => row.id !== newRow.id);
    unEditedRows.push(updatedRow);
    backendRows = unEditedRows;
    console.log(backendRows);
    return updatedRow;
  };

  let navigate = useNavigate();

  const sentAdminComments = async () => {
    setWait(true);
    let request_params = backendRows.map((item) => {
      delete item.id;
      delete item.load_dt;
      delete item["__typename"];
      delete item.isNew;
      delete item.create_userid;
      delete item.company_name;
      return item;
    });
    console.log(request_params);
    const createPublishData = await API.graphql({
      query: mutations.createPublishedDemographicProviderReconDetails,
      variables: {
        publish_recon_data: request_params,
        user_id: user,
      },
    }).then((data) => {
      console.log(data);
    });
    setWait(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    sentAdminComments();
    setAlert("success");
    setTimeout(() => {
      navigate("/report/manage-reconciliations");
    }, 800);
  };

  return (
    <>
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;} 
          .css-1si6n65{overflow-y: hidden!important;} 
          .css-frlfct{color:#9ca3af!important;} 
          .css-y599qu{color:#6b7280!important;}`}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar />
        <div className="2xl:ml-[14.5rem] h-fit w-fit">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/report" className="px-2">
              Report
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/report/reconcile1">
              Reconcile
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/report/reconcile2">
              Add Admin Comments
            </Link>
          </div>

          {/* Page and Action Buttons */}
          <div className="flex justify-between mt-4">
            <section className="flex items-center">
              <BsBoxes style={iconStyles} />
              <p className="text-3xl mx-1">Reconcile</p>
              <p className="text-[#0000A3] mt-2 text-sm">info</p>
            </section>
            {alert === "success" ? (
              <Alerts
                severity="success"
                info="Successfully, added your comments"
              />
            ) : alert === "error" ? (
              <Alerts severity="error" info="Try again later" />
            ) : (
              ""
            )}
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>

          {!isLoading && getValidDataResponseIndicator && (
            <div className="mt-10 w-full">
              <DataGrid
                style={{
                  // overflowY: "auto",
                  maxHeight: "450px",
                  // overflowX: "auto",
                  maxWidth: "1000px",
                }}
                rowHeight={30}
                rows={tempRows}
                // getRowId={(row) =>}
                // rows={rows.map((item, index) => ({ id: index + 1, ...item }))}
                columns={columns}
                processRowUpdate={processRowUpdate}
                stopEditingWhenCellsLoseFocus={true}
                onCellEditStop={(params, event) => {
                  if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                    event.defaultMuiPrevented = true;
                  }
                  if (params.reason === GridCellEditStopReasons.escapeKeyDown) {
                    event.defaultMuiPrevented = true;
                  }
                  if (params.reason === GridCellEditStopReasons.tabKeyDown) {
                    event.defaultMuiPrevented = true;
                  }
                  if (
                    params.reason === GridCellEditStopReasons.shiftTabKeyDown
                  ) {
                    event.defaultMuiPrevented = true;
                  }
                }}
                sx={{
                  "& .empty": {
                    backgroundColor: "#FCF3F2",
                    color: "#CC301A",
                    fontWeight: "600",
                  },
                  "& .fill": {
                    backgroundColor: "#EBF7DA",
                    color: "#2E5C0E",
                    fontWeight: "600",
                  },
                }}
                // hideFooter
                // hideFooterPagination
                // hideFooterSelectedRowCount
              />

              <div className="mt-16 flex justify-end items-center">
                <button
                  // onClick={(e) => {
                  //   handleClear(e);
                  // }}
                  className="ml-10 text-lg text-gray-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="ml-10 bg-[#0000A3] px-4 py-2 text-lg text-white"
                  onClick={(e) => {
                    handleSave(e);
                  }}
                >
                  Publish
                </button>
              </div>
              {/* </div> */}
              {console.log(tempRows)}
            </div>
          )}
          {/*isLoading && (
            <div className="mt-10 w-full">
              <h1>
                FETCHING THE STATUS FROM THE PROCESS KINDLY REFRESH IN EVERY 5
                SECOND INTERVAL
              </h1>
            </div>
          )}
          {!isLoading &&
            !getValidDataResponseIndicator &&
            isProcessCompleted && (
              <div className="mt-10 w-full">
                <h1>
                  The Database has not returned any values. Kindly check with
                  the concerned team
                </h1>
              </div>
            )*/}
        </div>
      </div>
    </>
  );
}

// <table className="ml-14">
//
{
  /*
                              *
                            const handleCellEditCommit = (newRow, oldRow) => {
                              editedRows.push(newRow);
                                console.log(editedRows)
                              // if(newRow.id !== editedRows.id){
                              //
                              //     editedRows.push(newRow)
                              // }
                              // setEditedRows([...editedRows, newRow]);
                              // console.log(editedRows);
                              // console.log(newRow);
                              //   editedRows.forEach((row) => (row.id===newRow.id))
                              // for (let row of editedRows) {
                              //     console.log(row.id)
                              // }
                              console.log(
                                editedRows.filter((row, index) => editedRows.indexOf(row) === index)
                              );
                              //
                              //
                              // if (row.id === newRow.id) return;
                              // else {
                              //   setEditedRows([...editedRows, newRow]);
                              // }
                              // if (row.id !== newRow.id) {
                              //   setEditedRows([...editedRows, newRow]);
                              // } else {
                              //   return;
                              // }
                            };
                              * Table */
}
{
  /*  
                                                <div className="flex">
                                                  <div className="ml-3">
                                                    <div className="flex flex-col my-4">
                                                      <p className=" text-gray-600">Step 1</p>
                                                      <p className="font-bold text-md pb-1 ">Set report properties</p>
                                                      <div className="w-full h-[1px] bg-black" />
                                                    </div>
                                                    <div className="flex flex-col my-4">
                                                      <p className="text-gray-600">Step 2</p>
                                                      <p className="text-gray-600 text-md pb-1 ">Add Admin Comments</p>
                                                      <div className="w-full h-[1px] bg-gray-300" />
                                                    </div>
                                                  </div>*/
}
//   <thead className="border-b border-black">
//     <tr className="flex space-x-2 mx-2 text-gray-500 mb-2">
//       <th className="flex justify-between h-[20px] w-[150px]">
//         <p className="text-sm">Col1</p>
//         <div className="flex">
//           <div onClick={() => setCaret((prev) => !prev)}>
//             {caret ? (
//               <AiOutlineCaretUp style={tableIconsStyles} />
//             ) : (
//               <AiOutlineCaretDown style={tableIconsStyles} />
//             )}
//           </div>
//           <div>
//             <MdFilterListAlt style={tableIconsStyles} />
//           </div>
//         </div>
//       </th>
//       <th className="flex justify-between h-[20px] w-[150px]">
//         <p className="text-sm">Col2</p>
//         <div className="flex">
//           <div onClick={() => setCaret((prev) => !prev)}>
//             {caret ? (
//               <AiOutlineCaretUp style={tableIconsStyles} />
//             ) : (
//               <AiOutlineCaretDown style={tableIconsStyles} />
//             )}
//           </div>
//           <div>
//             <MdFilterListAlt style={tableIconsStyles} />
//           </div>
//         </div>
//       </th>
//       <th className="flex justify-between h-[20px] w-[150px]">
//         <p className="text-sm">Col3</p>
//         <div className="flex">
//           <div onClick={() => setCaret((prev) => !prev)}>
//             {caret ? (
//               <AiOutlineCaretUp style={tableIconsStyles} />
//             ) : (
//               <AiOutlineCaretDown style={tableIconsStyles} />
//             )}
//           </div>
//           <div>
//             <MdFilterListAlt style={tableIconsStyles} />
//           </div>
//         </div>
//       </th>
//       <th className="flex justify-between h-[20px] w-[150px]">
//         <p className="text-sm">Col4</p>
//         <div className="flex">
//           <div onClick={() => setCaret((prev) => !prev)}>
//             {caret ? (
//               <AiOutlineCaretUp style={tableIconsStyles} />
//             ) : (
//               <AiOutlineCaretDown style={tableIconsStyles} />
//             )}
//           </div>
//           <div>
//             <MdFilterListAlt style={tableIconsStyles} />
//           </div>
//         </div>
//       </th>
//       <th className="flex justify-between h-[20px] w-[150px]">
//         <p className="text-sm">Col5</p>
//         <div className="flex">
//           <div onClick={() => setCaret((prev) => !prev)}>
//             {caret ? (
//               <AiOutlineCaretUp style={tableIconsStyles} />
//             ) : (
//               <AiOutlineCaretDown style={tableIconsStyles} />
//             )}
//           </div>
//           <div>
//             <MdFilterListAlt style={tableIconsStyles} />
//           </div>
//         </div>
//       </th>
//       <th className="flex justify-between h-[20px] w-[250px]">
//         <p className="text-sm">Administrator Comments</p>
//         <div className="flex">
//           <div onClick={() => setCaret((prev) => !prev)}>
//             {caret ? (
//               <AiOutlineCaretUp style={tableIconsStyles} />
//             ) : (
//               <AiOutlineCaretDown style={tableIconsStyles} />
//             )}
//           </div>
//           <div>
//             <MdFilterListAlt style={tableIconsStyles} />
//           </div>
//         </div>
//       </th>
//     </tr>
//   </thead>
//   <tbody>
//     {tableData.map((data, i) => (
//       <div
//         key={i}
//         className={`${i % 2 === 0 ? "flex" : "flex bg-gray-50"}`}
//       >
//         {console.log(i)}
//         <div className="text-gray-400 ml-2 my-2 h-full w-[150px]">
//           {data.col1}
//         </div>
//         <div className="text-gray-400 my-2 ml-2 h-full w-[150px]">
//           {data.col2}
//         </div>
//         <div className="text-gray-400 my-2 ml-2 h-full w-[150px]">
//           {data.col3}
//         </div>
//         <div className="text-gray-400 my-2 ml-2 h-full w-[150px]">
//           {data.col4}
//         </div>
//         <div className="text-gray-400 my-2 ml-2 h-full w-[150px]">
//           {data.col5}
//         </div>
//         <div className="text-gray-400 my-2 ml-2 h-full w-[150px]">
//           <div
//             className={`${
//               data.comment
//                 ? "ml-2 h-full my-1 w-[250px] bg-[#EBF7DA] text-center text-[#2E5C0E] rounded-md"
//                 : "ml-2 my-1 h-full rounded-md text-[#CC301A] w-[250px] bg-[#FCF3F2] text-center"
//             }`}
//           >
//             {data.comment ? "Label" : "None"}
//           </div>
//         </div>
//       </div>
//     ))}
//   </tbody>
// </table>
//
// const defaultRows = [
//   {
//     id: 1,
//     col1: "Company Name 1",
//     col2: "Template Type 1",
//     col3: "Template Name 1",
//     col4: "Country 1",
//     col5: "UserId 1",
//     comment: "Comment",
//   },
//   {
//     id: 2,
//     col1: "Company Name 2",
//     col2: "Template Type 2",
//     col3: "Template Name 2",
//     col4: "Country 2",
//     col5: "UserId 2",
//     comment: "",
//   },
//   {
//     id: 3,
//     col1: "Company Name 3",
//     col2: "Template Type 3",
//     col3: "Template Name 3",
//     col4: "Country 3",
//     col5: "UserId 3",
//     comment: "comment",
//   },
//   {
//     id: 4,
//     col1: "Company Name 4",
//     col2: "Template Type 4",
//     col3: "Template Name 4",
//     col4: "Country 4",
//     col5: "UserId 4",
//     comment: "comment",
//   },
//   {
//     id: 5,
//     col1: "Company Name 5",
//     col2: "Template Type 5",
//     col3: "Template Name 5",
//     col4: "Country 5",
//     col5: "UserId 5",
//     comment: "",
//   },
//   {
//     id: 6,
//     col1: "Company Name 1",
//     col2: "Template Type 1",
//     col3: "Template Name 1",
//     col4: "Country 1",
//     col5: "UserId 1",
//     comment: "Comment",
//   },
//   {
//     id: 7,
//     col1: "Company Name 2",
//     col2: "Template Type 2",
//     col3: "Template Name 2",
//     col4: "Country 2",
//     col5: "UserId 2",
//     comment: "",
//   },
//   {
//     id: 8,
//     col1: "Company Name 3",
//     col2: "Template Type 3",
//     col3: "Template Name 3",
//     col4: "Country 3",
//     col5: "UserId 3",
//     comment: "comment",
//   },
//   {
//     id: 9,
//     col1: "Company Name 4",
//     col2: "Template Type 4",
//     col3: "Template Name 4",
//     col4: "Country 4",
//     col5: "UserId 4",
//     comment: "comment",
//   },
//   {
//     id: 10,
//     col1: "Company Name 5",
//     col2: "Template Type 5",
//     col3: "Template Name 5",
//     col4: "Country 5",
//     col5: "UserId 5",
//     comment: "",
//   },
//   {
//     id: 6,
//     col3: "Company Name 5",
//     col5: "Template Type 5",
//     col7: "Template Name 5",
//     col9: "Country 5",
//     col11: "UserId 5",
//     comment: "",
//   },
// ];
//
// reconciliation_type,
// recon_status,
// recon_status_detail,
// administrator_comments,
// report_run_by_userid,
// report_request_id,
// report_run_date,
// snap_dt,
// provider_template_types,
// ee_template_types,
// provider_template_display_name,
// ee_template_display_name,
// emp_join_key_value,
// provider_join_key_value,
// country,
// company_id,
// emplid,
// provider_emplid,
// ee_emplid,
// provider_benefit_id,
// provider_plan_display_name,
// provider_member_unique_key_value,
// provider_national_id,
// national_ids,
// provider_first_name,
// ee_first_name,
// provider_middle_name,
// ee_middle_name,
// provider_last_name,
// ee_last_name,
// provider_date_of_birth,
// ee_date_of_birth,
// provider_coverage_begin_date,
// provider_coverage_end_date,
// provider_policy_id,
// provider_vendor_name,
// invoice_frequency,
// ee_recent_hire_date,
// ee_termination_date,
// ee_country_entry_date,
// ee_target_hr_status_code,
// ee_source_hr_status_code,
// ee_job_family,
// ee_target_employee_class,
// ee_source_employee_class,
//
//
// <div className="mt-10 w-full">
//   {!isProcessCompleted && (
//     <button
//       type="submit"
//       className="ml-10 bg-[#0000A3] px-4 py-2 text-lg text-white"
//       onClick={(e) => {
//         // handleSave(e);
//         getStoredProcStatus();
//       }}
//     >
//       Refresh the Status
//     </button>
//   )}
// </div>
