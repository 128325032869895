import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import { API } from "aws-amplify";
import { PiNumberCircleTwoFill, PiCheckCircleFill } from "react-icons/pi";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Alerts from "../../components/Alerts.jsx";
import * as mutations from "../../graphql/mutations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function UploadTemplateS3({ user, signOut }) {
  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "40px" };
  let numberStylesOn = { padding: "2px", color: "#0000A3", fontSize: "30px" };
  let numberStylesOut = { padding: "2px", color: "#AEB3B7", fontSize: "30px" };

  const [alert, setAlert] = useState("");
  const [masterJson, setMasterJson] = useState([]);
  const [wait, setWait] = useState(true);
  const [value0, setValue0] = useState("");
  const [value1, setValue1] = useState([]);
  const [value2, setValue2] = useState([]);
  const [value3, setValue3] = useState([]);
  const [value4, setValue4] = useState([]);
  const [value5, setValue5] = useState("");
  const [value6, setValue6] = useState([]);
  const [value7, setValue7] = useState([]);
  const [value8, setValue8] = useState([]);
  const [value9, setValue9] = useState([]);
  const [value10, setValue10] = useState("");
  const [value11, setValue11] = useState("");
  const [value12, setValue12] = useState("");
  const [value13, setValue13] = useState("");
  const [value14, setValue14] = useState("");
  const [value15, setValue15] = useState("");
  const [value16, setValue16] = useState("");
  const [value17, setValue17] = useState("");
  const [value18, setValue18] = useState("");
  const [value19, setValue19] = useState("");
  const [value20, setValue20] = useState("");
  const [value21, setValue21] = useState("");
  const [value22, setValue22] = useState("");
  const [value23, setValue23] = useState("");
  const [value24, setValue24] = useState("");
  const [value25, setValue25] = useState("");
  const [value26, setValue26] = useState("");
  const [value27, setValue27] = useState("");
  const [value28, setValue28] = useState("");
  const [value29, setValue29] = useState("");
  const [value30, setValue30] = useState("");
  const [value31, setValue31] = useState("");
  const [value32, setValue32] = useState("");
  const [value33, setValue33] = useState([]);
  const [value34, setValue34] = useState([]);
  const [value35, setValue35] = useState([]);
  const [value36, setValue36] = useState([]);
  const [value37, setValue37] = useState([]);
  const [value38, setValue38] = useState([]);
  const [value39, setValue39] = useState([]);
  const [value40, setValue40] = useState([]);
  const [value41, setValue41] = useState([]);
  const [value42, setValue42] = useState([]);
  const [value43, setValue43] = useState([]);

  const handleValue0Change = (event) => {
    setValue0(event.target.value);
  };
  const handleValue1Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue1(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue2Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue2(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue3Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue3(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue4Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue4(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue5Change = (event) => {
    setValue5(event.target.value);
  };

  const handleValue6Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue6(typeof value === "string" ? value.split(",") : value);
  };

  const handleValue7Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue7(typeof value === "string" ? value.split(",") : value);
  };

  const handleValue8Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue8(typeof value === "string" ? value.split(",") : value);
  };

  const handleValue9Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue9(typeof value === "string" ? value.split(",") : value);
  };

  const handleValue10Change = (event) => {
    setValue10(event.target.value);
  };

  const handleValue11Change = (event) => {
    setValue11(event.target.value);
  };
  const handleValue12Change = (event) => {
    setValue12(event.target.value);
  };
  const handleValue13Change = (event) => {
    setValue13(event.target.value);
  };
  const handleValue14Change = (event) => {
    setValue14(event.target.value);
  };
  const handleValue15Change = (event) => {
    setValue15(event.target.value);
  };
  const handleValue16Change = (event) => {
    setValue16(event.target.value);
  };
  const handleValue17Change = (event) => {
    setValue17(event.target.value);
  };
  const handleValue18Change = (event) => {
    setValue18(event.target.value);
  };
  const handleValue19Change = (event) => {
    setValue19(event.target.value);
  };
  const handleValue20Change = (event) => {
    setValue20(event.target.value);
  };
  const handleValue21Change = (event) => {
    setValue21(event.target.value);
  };
  const handleValue22Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue22(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue23Change = (event) => {
    setValue23(event.target.value);
  };
  const handleValue24Change = (event) => {
    setValue24(event.target.value);
  };
  const handleValue25Change = (event) => {
    setValue25(event.target.value);
  };
  const handleValue26Change = (event) => {
    setValue26(event.target.value);
  };
  const handleValue27Change = (event) => {
    setValue27(event.target.value);
  };
  const handleValue28Change = (event) => {
    setValue28(event.target.value);
  };
  const handleValue29Change = (event) => {
    setValue29(event.target.value);
  };
  const handleValue30Change = (event) => {
    setValue30(event.target.value);
  };
  const handleValue31Change = (event) => {
    setValue31(event.target.value);
  };
  const handleValue32Change = (event) => {
    setValue32(event.target.value);
  };
  const handleValue33Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue33(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue34Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue34(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue35Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue35(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue36Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue36(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue37Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue37(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue38Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue38(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue39Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue39(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue40Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue40(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue41Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue41(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue42Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue42(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue43Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue43(typeof value === "string" ? value.split(",") : value);
  };

  // const handleValue12Change = (event) => {
  //   setValue5(event.target.value);
  // };

  let costCategoryList = [];
  let costTypeList = [];
  let subPlanIdList = [];
  let countryList = [];
  let currencyCodeList = [];
  let callReasonList = [];
  let emailReasonList = [];
  let medicalClaimDiagnosisCategory = [];
  let medicalClaimProcedureCategory = [];
  let medicalProceduresClaimVolume = [];
  let medicalProceduresSpend = [];
  let webHits = [];
  let headcountList = [];
  let dentalClaimProcedureCategoryList = [];
  let visionClaimProcedureCategoryList = [];
  let inNetworkUsageCategoryList = [];
  let medicalServiceSettingCategoryList = [];
  let medicalClaimDiagnosisCategoryList = [];
  let eapServiceTypeList = [];
  let disabilitySubPlanIdList = [];
  let disabilityReasonsList = [];

  const getUploadAttributes = async (templateTypeId, benefitId) => {
    const filteredValues = await API.graphql({
      query: mutations.eFormfileTemplateTypeID,
      variables: {
        template_type_id: templateTypeId,
        benefit_id: benefitId,
      },
    });

    setMasterJson(filteredValues.data.eFormfileTemplateTypeID);
    setWait(false);
  };

  const templateTypeId = JSON.parse(
    localStorage.getItem("uploadValueTemplateType"),
  );

  useEffect(() => {
    const templateTypeId = JSON.parse(
      localStorage.getItem("uploadValueTemplateType"),
    );
    const benefitId = JSON.parse(
      localStorage.getItem("uploadValueBenefitProgramType"),
    );
    getUploadAttributes(templateTypeId, benefitId);
  }, []);
  console.log(templateTypeId);

  let trial = [];
  const uniqueArray = [];
  const seenItems = {};

  for (var item of masterJson) {
    trial.push(item.attribute_key_name_parent);
  }

  for (const item of trial) {
    if (!seenItems[item]) {
      uniqueArray.push(item);
      seenItems[item] = true;
    }
  }
  console.log(uniqueArray);

  if (uniqueArray.includes("cost_category")) {
    for (let idep of masterJson) {
      if (idep.attribute_key_name_parent === "cost_category") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        costCategoryList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("cost_type")) {
    for (let idep of masterJson) {
      if (idep.attribute_key_name_parent === "cost_type") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        costTypeList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("sub_plan_id")) {
    for (let idep of masterJson) {
      if (idep.attribute_key_name_parent === "sub_plan_id") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        subPlanIdList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("country")) {
    for (let idep of masterJson) {
      if (idep.attribute_key_name_parent === "country") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        countryList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("currency_code")) {
    for (let idep of masterJson) {
      if (idep.attribute_key_name_parent === "currency_code") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        currencyCodeList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("call_reasons")) {
    for (let idep of masterJson) {
      if (idep.attribute_key_name_parent === "call_reasons") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        callReasonList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("email_reasons")) {
    for (let idep of masterJson) {
      if (idep.attribute_key_name_parent === "email_reasons") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        emailReasonList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("medical_claim_diagnosis_category")) {
    for (let idep of masterJson) {
      if (
        idep.attribute_key_name_parent === "medical_claim_diagnosis_category"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        medicalClaimDiagnosisCategory.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("medical_claim_procedure_category")) {
    for (let idep of masterJson) {
      if (
        idep.attribute_key_name_parent === "medical_claim_procedure_category"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        medicalClaimProcedureCategory.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("medical_procedures_claim_volume")) {
    for (let idep of masterJson) {
      if (
        idep.attribute_key_name_parent === "medical_procedures_claim_volume"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        medicalProceduresClaimVolume.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("medical_procedures_spend")) {
    for (let idep of masterJson) {
      if (idep.attribute_key_name_parent === "medical_procedures_spend") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        medicalProceduresSpend.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("web_hits")) {
    for (let idep of masterJson) {
      if (idep.attribute_key_name_parent === "web_hits") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        webHits.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("headcount_type")) {
    for (let idep of masterJson) {
      if (idep.attribute_key_name_parent === "headcount_type") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        headcountList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("dental_claim_procedure_category")) {
    for (let idep of masterJson) {
      if (
        idep.attribute_key_name_parent === "dental_claim_procedure_category"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        dentalClaimProcedureCategoryList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("vision_claim_procedure_category")) {
    for (let idep of masterJson) {
      if (
        idep.attribute_key_name_parent === "vision_claim_procedure_category"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        visionClaimProcedureCategoryList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("in_network_usage_category")) {
    for (let idep of masterJson) {
      if (idep.attribute_key_name_parent === "in_network_usage_category") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        inNetworkUsageCategoryList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("medical_service_setting_categoy")) {
    for (let idep of masterJson) {
      if (
        idep.attribute_key_name_parent === "medical_service_setting_categoy"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        medicalServiceSettingCategoryList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("eap_service_type")) {
    for (let idep of masterJson) {
      if (idep.attribute_key_name_parent === "eap_service_type") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        eapServiceTypeList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("disability_sub_plan_id")) {
    for (let idep of masterJson) {
      if (idep.attribute_key_name_parent === "disability_sub_plan_id") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        disabilitySubPlanIdList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("disability_reasons")) {
    for (let idep of masterJson) {
      if (idep.attribute_key_name_parent === "disability_reasons") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        disabilityReasonsList.push(dict1);
      }
    }
  }

  let duplicateFinalList = [
    ["vendor", value0],
    ["cost_category", value1],
    ["cost_type", value2],
    ["sub_plan_id", value3],
    ["country", value4],
    ["currency_code", value5],
    ["call_reasons", value6],
    ["email_reasons", value7],
    ["medical_claim_diagnosis_category", value8],
    ["medical_claim_procedure_category", value9],
    ["medical_procedures_claim_volume", value10],
    ["medical_procedures_spend", value11],
    ["sla_1", value12],
    ["sla_2", value13],
    ["sla_3", value14],
    ["sla_4", value15],
    ["sla_5", value16],
    ["sla_6", value17],
    ["sla_7", value18],
    ["sla_8", value19],
    ["sla_9", value20],
    ["sla_10", value21],
    ["web_hits", value22],
    ["sla_1", value23],
    ["sla_2", value24],
    ["sla_3", value25],
    ["sla_4", value26],
    ["sla_5", value27],
    ["sla_6", value28],
    ["sla_7", value29],
    ["sla_8", value30],
    ["sla_9", value31],
    ["sla_10", value32],
    ["headcount_type", value33],
    ["dental_claim_procedure_category", value34],
    ["vision_claim_procedure_category", value35],
    ["in_network_usage_category", value36],
    ["medical_service_setting_categoy", value37],
    ["eap_service_type", value38],
    ["disability_sub_plan_id", value39],
    ["disability_reasons", value40],
  ];

  let finalList = [];

  for (var i of duplicateFinalList) {
    if (i[1].length > 0) {
      finalList.push(i);
    }
  }

  console.log(uniqueArray);

  const handleSave = async (e) => {
    if (finalList.length === 0) {
      e.preventDefault();
      setAlert("error");
    } else {
      console.log(finalList);
      e.preventDefault();
      localStorage.removeItem("uploadTemplate");
      setAlert("success");
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {" "}
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;}
            .required:after{content: "*"; color: red;}
            input[type="checkbox"]{accent-color:#0000A3;}
          `}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar />
        <div className="2xl:ml-[15rem] ml-12">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/integrate" className="px-2">
              Integrate
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/upload-select">
              Upload
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/uploadtemplateS2">
              E-Form
            </Link>
          </div>
          {/* page Heading*/}
          <div className="flex justify-between">
            <section className="flex items-center">
              <MdOutlineAutoFixHigh style={iconStyles} />
              <p className="text-3xl mx-1">Create E-Form</p>
              <p className="text-[#0000A3] mt-2">info</p>
            </section>

            {alert === "success" ? (
              <Alerts
                severity="success"
                info="Your file has been received, visit the manage reports page to track status"
              />
            ) : alert === "error" ? (
              <Alerts severity="error" info="Try again later" />
            ) : (
              ""
            )}
          </div>
          {/* form*/}

          <div className="flex">
            <div className="ml-3">
              <div className="flex items-center space-x-2">
                <PiCheckCircleFill style={numberStylesOn} />
                <div className="flex flex-col my-4">
                  <p className=" text-gray-600">Step 1</p>
                  <p className="text-md pb-1 ">Select E-Form Type</p>
                  <div className="w-full h-[1px] bg-gray-200" />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <PiNumberCircleTwoFill style={numberStylesOn} />
                <div className="flex flex-col my-4">
                  <p className="text-gray-600">Step 2</p>
                  <p className="  font-bold text-md pb-1 ">
                    Create E-Form Template
                  </p>
                  <div className="w-full h-[1px] bg-gray-200" />
                </div>{" "}
              </div>
            </div>

            {wait ? (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={wait}
              >
                <CircularProgress sx={{ color: "#DB2748" }} />{" "}
              </Backdrop>
            ) : (
              <div className="w-[615px] ml-16 mt-8">
                <Paper elevation={2}>
                  <div className="px-10 pt-10">
                    <div className="">
                      <div className="flex text-center flex-col space-y-4">
                        <div className="font-medium text-2xl capitalize">
                          {templateTypeId.replace(/_/g," ")}
                          
                        </div>
                        <div>
                          Select the attributes to include in your E-Form
                          Template
                        </div>
                      </div>
                    </div>
                    <form className="ml-5 grid grid-cols-2 gap-8 mt-16">
                      {uniqueArray.includes("vendor") && (
                        <>
                          {" "}
                          <FormControl fullWidth>
                            <TextField
                              id="PR12348"
                              value={value0}
                              label={"Vendor Name"}
                              onChange={handleValue0Change}
                              variant="outlined"
                            />
                          </FormControl>
                          <FormControl fullWidth></FormControl>
                        </>
                      )}

                      {uniqueArray.includes("cost_category") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Cost Category"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value1}
                              label={"Value"}
                              multiple
                              onChange={handleValue1Change}
                            >
                              {costCategoryList.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("cost_type") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Cost Type"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value2}
                              label={"Value"}
                              multiple
                              onChange={handleValue2Change}
                            >
                              {costTypeList.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sub_plan_id") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Sub Plans"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value3}
                              label={"Value"}
                              multiple
                              onChange={handleValue3Change}
                            >
                              {subPlanIdList.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("country") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Country"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value4}
                              label={"Value"}
                              onChange={handleValue4Change}
                              multiple
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {countryList.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("currency_code") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Currency Code"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value5}
                              label={"Value"}
                              onChange={handleValue5Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {currencyCodeList.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("call_reasons") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Contact Reason Volume"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value6}
                              label={"Value"}
                              multiple
                              onChange={handleValue6Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {callReasonList.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("email_reasons") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Web Transactions"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value7}
                              label={"Value"}
                              multiple
                              onChange={handleValue7Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {emailReasonList.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes(
                        "medical_claim_diagnosis_category",
                      ) && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Medical Service Claim Volume"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value8}
                              label={"Value"}
                              multiple
                              onChange={handleValue8Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {medicalClaimDiagnosisCategory.map(
                                (item, key) => (
                                  <MenuItem value={item.listValueKey} key={key}>
                                    {item.listValueDisplayName}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes(
                        "medical_claim_procedure_category",
                      ) && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Medical Claim Procedures"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value9}
                              label={"Value"}
                              multiple
                              onChange={handleValue9Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {medicalClaimProcedureCategory.map(
                                (item, key) => (
                                  <MenuItem value={item.listValueKey} key={key}>
                                    {item.listValueDisplayName}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes(
                        "medical_procedures_claim_volume",
                      ) && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Medical Claim Volume"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value10}
                              label={"Value"}
                              onChange={handleValue10Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {medicalProceduresClaimVolume.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("medical_procedures_spend") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Medical Spend"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value11}
                              label={"Value"}
                              onChange={handleValue11Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {currencyCodeList.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_1") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="Coverage Rule"
                              id="outlined-basic"
                              value={"SLA 1"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              id="BPCD123418"
                              value={value12}
                              label={"Value"}
                              onChange={handleValue12Change}
                              variant="outlined"
                            />
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_2") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="Coverage Rule"
                              id="outlined-basic"
                              value={"SLA 2"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              id="BPCD123418"
                              value={value13}
                              label={"Value"}
                              onChange={handleValue13Change}
                              variant="outlined"
                            />
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_3") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="Coverage Rule"
                              id="outlined-basic"
                              value={"SLA 3"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              id="BPCD123418"
                              value={value14}
                              label={"Value"}
                              onChange={handleValue14Change}
                              variant="outlined"
                            />
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_4") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="Coverage Rule"
                              id="outlined-basic"
                              value={"SLA 4"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              id="BPCD123418"
                              value={value15}
                              label={"Value"}
                              onChange={handleValue15Change}
                              variant="outlined"
                            />
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_5") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="Coverage Rule"
                              id="outlined-basic"
                              value={"SLA 5"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              id="BPCD123418"
                              value={value16}
                              label={"Value"}
                              onChange={handleValue16Change}
                              variant="outlined"
                            />
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_6") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="Coverage Rule"
                              id="outlined-basic"
                              value={"SLA 6"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              id="BPCD123418"
                              value={value17}
                              label={"Value"}
                              onChange={handleValue17Change}
                              variant="outlined"
                            />
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_7") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="Coverage Rule"
                              id="outlined-basic"
                              value={"SLA 7"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              id="BPCD123418"
                              value={value18}
                              label={"Value"}
                              onChange={handleValue18Change}
                              variant="outlined"
                            />
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_8") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="Coverage Rule"
                              id="outlined-basic"
                              value={"SLA 8"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              id="BPCD123418"
                              value={value19}
                              label={"Value"}
                              onChange={handleValue19Change}
                              variant="outlined"
                            />
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_9") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="Coverage Rule"
                              id="outlined-basic"
                              value={"SLA 9"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              id="BPCD123418"
                              value={value20}
                              label={"Value"}
                              onChange={handleValue20Change}
                              variant="outlined"
                            />
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_10") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="Coverage Rule"
                              id="outlined-basic"
                              value={"SLA 10"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              id="BPCD123418"
                              value={value21}
                              label={"Value"}
                              onChange={handleValue21Change}
                              variant="outlined"
                            />
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("web_hits") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Web Transactions"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value22}
                              label={"Value"}
                              onChange={handleValue22Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {webHits.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_1") && (
                        <>
                          {" "}
                          <FormControl fullWidth>
                            <TextField
                              id="PR12348"
                              value={value23}
                              label={"SLA 1"}
                              onChange={handleValue23Change}
                              variant="outlined"
                            />
                          </FormControl>
                          <FormControl fullWidth></FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_2") && (
                        <>
                          {" "}
                          <FormControl fullWidth>
                            <TextField
                              id="PR12348"
                              value={value24}
                              label={"SLA 2"}
                              onChange={handleValue24Change}
                              variant="outlined"
                            />
                          </FormControl>
                          <FormControl fullWidth></FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_3") && (
                        <>
                          {" "}
                          <FormControl fullWidth>
                            <TextField
                              id="PR32348"
                              value={value25}
                              label={"SLA 3"}
                              onChange={handleValue25Change}
                              variant="outlined"
                            />
                          </FormControl>
                          <FormControl fullWidth></FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_4") && (
                        <>
                          {" "}
                          <FormControl fullWidth>
                            <TextField
                              id="PR42348"
                              value={value26}
                              label={"SLA 4"}
                              onChange={handleValue26Change}
                              variant="outlined"
                            />
                          </FormControl>
                          <FormControl fullWidth></FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_5") && (
                        <>
                          {" "}
                          <FormControl fullWidth>
                            <TextField
                              id="PR52348"
                              value={value27}
                              label={"SLA 5"}
                              onChange={handleValue27Change}
                              variant="outlined"
                            />
                          </FormControl>
                          <FormControl fullWidth></FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_6") && (
                        <>
                          {" "}
                          <FormControl fullWidth>
                            <TextField
                              id="PR62348"
                              value={value28}
                              label={"SLA 6"}
                              onChange={handleValue28Change}
                              variant="outlined"
                            />
                          </FormControl>
                          <FormControl fullWidth></FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_7") && (
                        <>
                          {" "}
                          <FormControl fullWidth>
                            <TextField
                              id="PR72348"
                              value={value29}
                              label={"SLA 7"}
                              onChange={handleValue29Change}
                              variant="outlined"
                            />
                          </FormControl>
                          <FormControl fullWidth></FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_8") && (
                        <>
                          {" "}
                          <FormControl fullWidth>
                            <TextField
                              id="PR82348"
                              value={value30}
                              label={"SLA 8"}
                              onChange={handleValue30Change}
                              variant="outlined"
                            />
                          </FormControl>
                          <FormControl fullWidth></FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_9") && (
                        <>
                          {" "}
                          <FormControl fullWidth>
                            <TextField
                              id="PR13148"
                              value={value31}
                              label={"SLA 9"}
                              onChange={handleValue31Change}
                              variant="outlined"
                            />
                          </FormControl>
                          <FormControl fullWidth></FormControl>
                        </>
                      )}

                      {uniqueArray.includes("sla_10") && (
                        <>
                          {" "}
                          <FormControl fullWidth>
                            <TextField
                              id="PR103248"
                              value={value32}
                              label={"SLA 10"}
                              onChange={handleValue32Change}
                              variant="outlined"
                            />
                          </FormControl>
                          <FormControl fullWidth></FormControl>
                        </>
                      )}

                      {uniqueArray.includes("headcount_type") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Eligible Headcount"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value33}
                              label={"Value"}
                              multiple
                              onChange={handleValue33Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {headcountList.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes(
                        "dental_claim_procedure_category",
                      ) && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Dental Claim Usage"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value34}
                              label={"Value"}
                              multiple
                              onChange={handleValue34Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {dentalClaimProcedureCategoryList.map(
                                (item, key) => (
                                  <MenuItem value={item.listValueKey} key={key}>
                                    {item.listValueDisplayName}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes(
                        "vision_claim_procedure_category",
                      ) && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Vision Claim Usage"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value35}
                              label={"Value"}
                              multiple
                              onChange={handleValue35Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {visionClaimProcedureCategoryList.map(
                                (item, key) => (
                                  <MenuItem value={item.listValueKey} key={key}>
                                    {item.listValueDisplayName}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("in_network_usage_category") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Network Utilization"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value36}
                              label={"Value"}
                              multiple
                              onChange={handleValue36Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {inNetworkUsageCategoryList.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes(
                        "medical_service_setting_categoy",
                      ) && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Medical Service Setting"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value37}
                              label={"Value"}
                              multiple
                              onChange={handleValue37Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {medicalServiceSettingCategoryList.map(
                                (item, key) => (
                                  <MenuItem value={item.listValueKey} key={key}>
                                    {item.listValueDisplayName}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("eap_service_type") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"EAP Usage Service Type"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value38}
                              label={"Value"}
                              multiple
                              onChange={handleValue38Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {eapServiceTypeList.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("disability_sub_plan_id") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Disability Plan Type"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value39}
                              label={"Value"}
                              multiple
                              onChange={handleValue39Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {disabilitySubPlanIdList.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}

                      {uniqueArray.includes("disability_reasons") && (
                        <>
                          <FormControl fullWidth>
                            <TextField
                              label="E-Form Attribute"
                              id="outlined-basic"
                              value={"Disability Reasons"}
                              variant="outlined"
                              inputProps={{ readOnly: true }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="PR1234142">{"Value"}</InputLabel>
                            <Select
                              labelId="PR1234154"
                              id="PR1234166"
                              value={value40}
                              label={"Value"}
                              multiple
                              onChange={handleValue40Change}
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 250 } },
                              }}
                            >
                              {disabilityReasonsList.map((item, key) => (
                                <MenuItem value={item.listValueKey} key={key}>
                                  {item.listValueDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}
                      <div className="pt-16 pb-12 col-start-1 col-end-3 w-full flex justify-end items-center">
                        <button
                          onClick={(e) => {
                            handleClear(e);
                          }}
                          className="ml-10 bg-gray-100 rounded-md text-gray-600 shadow-black shadow-[0_1px_4px_rgb(0,0,0,0.2)] py-[6px] px-5"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="ml-5 bg-[#DB2748] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white"
                          onClick={(e) => {
                            handleSave(e);
                          }}
                        >
                          Create
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="pt-5">
                    {" "}
                    {finalList.map((item, key) => (
                      <div key={key}>
                        {item[0]} : {item[1]}
                      </div>
                    ))}
                  </div>
                </Paper>
              </div>
            )}
          </div>
          <p className="mt-14 text-center text-gray-400">
            Uploaded file(s) must be in the same format specified in template.
            <br />
            Please go to{" "}
            <span className="text-[#13B7FE]">'Manage Template'</span> if there
            is an update required for an existing template.
          </p>
        </div>
      </div>
    </>
  );
}

// ["web_hits", value22],
