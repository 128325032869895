import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Alerts from "../../components/Alerts.jsx";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { PiNumberCircleOneFill, PiNumberCircleTwoFill } from "react-icons/pi";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries.js";
import * as mutations from "../../graphql/mutations.js";
import Paper from "@mui/material/Paper";

export default function CreateBenefitS1({ user, signOut }) {
  const [wait, setWait] = useState(true);
  const [benefitProgram, setBenefitProgram] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");
  const [planName, setPlanName] = useState("");
  const [alert, setAlert] = useState("");
  const [masterJson, setMasterJson] = useState([]);
  const [tempCompanyNames, setTempCompanyNames] = useState([]);
  let companyNameList = [];
  let benefitNameList = [];
  let countryList = [];

  useEffect(() => {
    const getCreateTemplateCompanyNames = async () => {
      const createTemplateCompanyNames = await API.graphql({
        query: mutations.CompanyListUseridFilter,
        variables: {
          userid: user,
        },
      });
      setTempCompanyNames(
        createTemplateCompanyNames.data.CompanyListUseridFilter,
      );
      setWait(false);
    };

    const getBenefitAttributes = async () => {
      const benefitAttributes = await API.graphql({
        query: queries.getCommonValues,
      });

      setMasterJson(benefitAttributes.data.getCommonValues);
      setWait(false);
    };

    getCreateTemplateCompanyNames();
    getBenefitAttributes();
  }, []);

  // Data storing and modification
  for (var cn of tempCompanyNames) {
    let tempCompanyName = {
      companyNameId: "",
      companyNameDisplayName: "",
    };
    tempCompanyName.companyNameId = cn.company_id;
    tempCompanyName.companyNameDisplayName = cn.company_name;
    companyNameList.push(tempCompanyName);
  }

  for (var bp of masterJson) {
    if (bp.attribute_key_name === "benefit_id") {
      let tempBenefitList = {
        benefitId: "",
        benefitDisplayName: "",
        benefitSortOrder: "",
      };
      tempBenefitList.benefitId = bp.attribute_list_valid_value;
      tempBenefitList.benefitDisplayName = bp.attribute_list_value_display_name;
      tempBenefitList.benefitSortOrder = bp.attribute_list_value_sort_order;
      benefitNameList.push(tempBenefitList);
    }
  }

  for (var c of masterJson) {
    if (c.attribute_key_name === "country") {
      let tempCountryList = {
        countryId: "",
        countryDisplayName: "",
        countrySortOrder: "",
      };
      tempCountryList.countryId = c.attribute_list_valid_value;
      tempCountryList.countryDisplayName = c.attribute_list_value_display_name;
      tempCountryList.countrySortOrder = c.attribute_list_value_sort_order;
      countryList.push(tempCountryList);
    }
  }

  countryList.sort((a, b) => a.countrySortOrder - b.countrySortOrder);
  benefitNameList.sort((a, b) => a.benefitSortOrder - b.benefitSortOrder);

  let navigate = useNavigate();

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };
  let numberStylesOn = { padding: "2px", color: "#0000A3", fontSize: "30px" };
  let numberStylesOut = { padding: "2px", color: "#AEB3B7", fontSize: "30px" };

  const handleBenefitProgramChange = (event) => {
    setBenefitProgram(event.target.value);
  };
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };
  const handlePlanNameChange = (event) => {
    setPlanName(event.target.value);
  };

  useEffect(() => {
    localStorage.setItem("contryFromBenefitPage1", JSON.stringify(country));
    localStorage.setItem(
      "companyFromBenefitPage1",
      JSON.stringify(companyName),
    );
    localStorage.setItem("planNameFromBenefitPage1", JSON.stringify(planName));
    localStorage.setItem(
      "benefitProgramFromBenefitPage1",
      JSON.stringify(benefitProgram),
    );
  });

  const handleSave = async (e) => {
    if (
      benefitProgram.length <= 0 ||
      companyName.length <= 0 ||
      country.length <= 0 ||
      planName.length <= 0
    ) {
      e.preventDefault();
      setAlert("error");
    } else {
      e.preventDefault();
      // const sendingBenefitStep1Values = await API.graphql({
      //   query: mutations.createBenefitDetails,
      //   variables: {
      //     benefit_plan_display_name: planName,
      //     benefit_status: "A",
      //     benefit_id: benefitProgram,
      //     company_id: companyName,
      //     country: country,
      //     attribute_key: "",
      //     attribute_value: "",
      //     userid: user,
      //     operator_id: "",
      //     attribute_category: "",
      //   },
      // }).then((data) => {
      //   console.log(data);
      // });

      setAlert("success");
      navigate("/design/createbenefits2");
      // window.location.reload();
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {" "}
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;}
            .required:after{content: "*"; color: red;}
          .save:{box-shadow: 0px 10px 15px 19px rgba(0,0,0,0.1);}`}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar />
        <div className="2xl:ml-[12rem] ml-12">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/design" className="px-2">
              Benefit
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/design/createbenefits1">
              Benefit Profile
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/design/createbenefits1">
              Create Benefit Profile
            </Link>
          </div>

          {/* page Heading*/}
          <div>
            <section className="flex items-center">
              <MdOutlineAutoFixHigh style={iconStyles} />
              <p className="text-3xl mx-1">Create Benefit</p>
              <p className="text-[#0000A3] mt-2">info</p>
            </section>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>

          <div className="flex">
            {/*steps*/}
            <div className="ml-3">
              <div className="flex items-center space-x-2">
                <PiNumberCircleOneFill style={numberStylesOn} />
                <div className="flex flex-col my-4">
                  <p className=" text-gray-600">Step 1</p>
                  <p className="font-bold text-md pb-1 ">
                    Create Benefit Profile
                  </p>
                  <div className="w-full h-[1px] bg-gray-200" />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <PiNumberCircleTwoFill style={numberStylesOut} />
                <div className="flex flex-col my-4">
                  <p className=" text-gray-600">Step 2</p>
                  <p className=" text-md pb-1 ">Additional Details</p>
                  <div className="w-full h-[1px] bg-gray-200" />
                </div>{" "}
              </div>
            </div>

            {/* form*/}
            <div className="mt-10 w-[650px] h-3/4 ml-[3.25rem]">
              <Paper elevation={2}>
                <form className="pt-6 grid grid-cols-2">
                  <div className="flex flex-col mx-14 my-3">
                    <div className=" w-full relative inline-block">
                      <FormControl
                        name="fullWidth1"
                        fullWidth
                        error={companyName.length > 0 ? false : true}
                      >
                        <InputLabel id="cn2">Company Name</InputLabel>
                        <Select
                          labelId="cn3"
                          id="cn4"
                          value={companyName}
                          label="Company Name"
                          onChange={handleCompanyNameChange}
                          className={companyName.length > 0 ? "fill" : "empty"}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 430 } } }}
                        >
                          {companyNameList.map((name, key) => (
                            <MenuItem key={key} value={name.companyNameId}>
                              {name.companyNameDisplayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="flex flex-col mx-14 my-3">
                    <div className=" w-full relative inline-block">
                      <FormControl
                        name="fullWidth1"
                        fullWidth
                        error={country.length > 0 ? false : true}
                      >
                        <InputLabel id="c2">Country</InputLabel>
                        <Select
                          labelId="c3"
                          id="c4"
                          value={country}
                          label="country"
                          onChange={handleCountryChange}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 430 } } }}
                        >
                          {countryList.map((name, key) => (
                            <MenuItem key={key} value={name.countryId}>
                              {name.countryDisplayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <hr className="h-[1px] bg-[#7A7A7A] my-4 mx-14 grid col-start-1 col-end-3" />

                  <div className="flex flex-col mx-14 my-3">
                    <div className=" w-full relative inline-block">
                      <FormControl
                        name="fullWidth1"
                        fullWidth
                        error={benefitProgram.length > 0 ? false : true}
                      >
                        <InputLabel id="bp2">Benefit Program</InputLabel>
                        <Select
                          labelId="bp3"
                          id="bp4"
                          value={benefitProgram}
                          label="benefit Program"
                          onChange={handleBenefitProgramChange}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                        >
                          {benefitNameList.map((name, key) => (
                            <MenuItem key={key} value={name.benefitId}>
                              {name.benefitDisplayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="flex flex-col mx-14 my-3">
                    <div className="w-full relative inline-block">
                      <TextField
                        name="fullWidth1"
                        fullWidth
                        value={planName}
                        onChange={handlePlanNameChange}
                        id="planName"
                        label="Plan Name"
                        variant="outlined"
                        error={planName.length > 0 ? false : true}
                      />
                    </div>
                  </div>

                  <div className="h-1" />
                  <div className="mt-14 pb-4 pr-4 w-full flex justify-end items-center">
                    <button
                      onClick={(e) => {
                        handleClear(e);
                      }}
                      className="ml-5 bg-gray-200 py-[6px]  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] px-6  text-gray-500"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="ml-5 bg-[#DB2748] py-[6px]  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] px-6 text-white"
                      onClick={(e) => {
                        handleSave(e);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </form>
              </Paper>
              <div className="mt-4 px-6">
                {alert === "success" ? (
                  <Alerts
                    severity="success"
                    info="Step 1, successfully completed"
                  />
                ) : alert === "error" ? (
                  <Alerts
                    severity="error"
                    info="Fill all the fields to successfully complete the Step 1"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// <div className="pt-10">
//   {" "}
//   {JSON.stringify(
//     [companyName, country, benefitProgram, planName],
//     null,
//     "    ",
//   )}
// </div>

// <div className="pt-10">
//   {" "}
//   {JSON.stringify(
//     [
//       template.templateType,
//       template.templateName,
//       template.companyName,
//       template.country,
//     ],
//     null,
//     "    "
//   )}
// </div>
//
{
  /*
                        <div className="flex items-center space-x-2">
                          <PiNumberCircleThreeFill style={numberStylesOut} />
                          <div className="flex flex-col my-4">
                            <p className=" text-gray-600">Step 3</p>
                            <p className=" text-md pb-1 ">Eligibility</p>
                            <div className="w-full h-[1px] bg-gray-200" />
                          </div>{" "}
                        </div>
          */
}
