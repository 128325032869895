import React from "react";
import {BsMagic,BsCardChecklist,BsCloudUpload, BsBell, BsBoxArrowInRight, BsBoxes, BsEject } from "react-icons/bs";
import {MdOutlineStorage} from "react-icons/md";
import { Link } from "react-router-dom";
import LinkCard from "../../components/LinkCard";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Paper from "@mui/material/Paper";

export default function integrateLandingPage({user, signOut}) {
  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "60px" };
  // <Route path="/createtemplates1" element={<CreateTemplateS1 />} />
  // <Route path="/createtemplates2" element={<CreateTemplateS2 />} />
  // <Route path="/createtemplates3" element={<CreateTemplateS3 />} />
  // <Route path="/createtemplates4" element={<CreateTemplateS4 />} />

  return (
    <>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar />
        <div className="pt-5 2xl:pt-24  h-fit xl:mx-40 xl:mr-[25rem] 2xl:mr-[40rem] 2xl:ml-[25rem] ">
          <Paper className="mt-6 pb-14 pl-1" elevation={2}>
          <div className="w-[584px] h-[558px] grid grid-cols-2 justify-center items-center">
            <Link to="/integrate/select">
              <LinkCard
                icon={<BsMagic style={iconStyles} />}
                linkText="Create Template"
              />
            </Link>
            <Link to="/integrate/manage-template">
              <LinkCard
                icon={<BsCardChecklist style={iconStyles} />}
                linkText="Manage Template"
              />
            </Link>
            <Link to="/integrate/upload-select">
              <LinkCard
                icon={<BsCloudUpload style={iconStyles} />}
                linkText="Upload/Schedule "
              />
            </Link>
            <Link to="/integrate/manage-files">
              <LinkCard
                icon={<MdOutlineStorage style={iconStyles} />}
                linkText="Manage Files"
              />
            </Link>
          </div>
          </Paper>
        </div>
      </div>
    </>
  );
}

