import { BsBoxes } from "react-icons/bs";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { React, useState, useEffect, useRef } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries.js";
import * as mutations from "../../graphql/mutations.js";

export default function ManageCompaniesPage({ user, signOut }) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
    const [wait, setWait] = useState(true)
  const allCompanyProfiles = async () => {
    // setLoading(true);
    const profiles = await API.graphql({
      query: mutations.CompanyListUseridFilter,
      variables: { userid: user },
    });
    setRows(profiles.data.CompanyListUseridFilter);
      setWait(false)
  };

  useEffect(() => {
    setLoading(true);
    allCompanyProfiles();
    setLoading(false);
  }, []);

  // console.log("rows",rows);

  const columns = [
    {
      field: "company_id",
      renderHeader: () => <strong>{"Id"}</strong>,
      width: 130,
    },
    {
      field: "company_name",
      renderHeader: () => <strong>{"Company"}</strong>,
      width: 130,
    },
    {
      field: "company_country_hq",
      renderHeader: () => <strong>{"HQ Country"}</strong>,
      width: 110,
    },
    {
      field: "company_state_hq",
      renderHeader: () => <strong>{"HQ Region"}</strong>,
      width: 110,
    },
    {
      field: "industry_name",
      renderHeader: () => <strong>{"Industry"}</strong>,
      width: 150,
    },
    {
      field: "sub_industry_name",
      renderHeader: () => <strong>{"Sub Industry"}</strong>,
      width: 150,
    },
    {
      field: "as_of_date",
      renderHeader: () => <strong>{"Last Updated Date"}</strong>,
      width: 130,
    },
    {
      field: "userid",
      renderHeader: () => <strong>{"UserId"}</strong>,
      width: 130,
    },
  ];

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };

  const navigate = useNavigate();

  const [action, setAction] = useState(false);

  let actionRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!actionRef.current.contains(e.target)) {
        setAction(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleDeleteClick = async () => {
    let newRows = rows.filter((row) => !selectedRows.includes(row.company_id));
    let delRows = rows.filter((row) => selectedRows.includes(row.company_id));
    let delapirows = delRows.map(row=>({company_id : row.company_id}));
    console.log(delapirows)
    setRows(newRows);
    const deleteOperation = await API.graphql({
      query: mutations.deleteCompanyDetails,
      variables: {
        delete_company_details: delapirows,
        userid: user,
      },
    });
    // console.log(delapirows)
    // setAlert("error");
    // export const deleteCompany = /* GraphQL */ `
    //   mutation DeleteCompany($company_id: String, $userid: String) {
    //     deleteCompany(company_id: $company_id, userid: $userid) {
    //       as_of_date
    //       company_country_hq
    //       company_country_hq_intermediate_region
    //       company_country_hq_name
    //       company_country_hq_region_name
    //       company_country_hq_sub_region_name
    //       company_country_hq_two_digit_iso_code
    //       company_id
    //       company_name
    //       company_state_hq
    //       company_state_hq_name
    //       company_status
    //       industry_id
    //       industry_name
    //       is_exclude_from_benchmarks
    //       poc_email
    //       poc_role
    //       primary_poc
    //       sub_industry_id
    //       sub_industry_name
    //       employer_size
    //       employer_size_display_name
    //       oranization_id
    //       organization_name
    //       userid
    //     }
    //   }
    // `;
    console.log(deleteOperation);
  };

  const handleSelectedRows = (row) => {
    // console.log(row);
    setSelectedRows(row);
  };

  return (
    <>
      {" "}
      <style>{`.css-1si6n65{overflow-y: hidden!important;} .css-frlfct{color:#9ca3af!important;} .css-y599qu{color:#6b7280!important;}`}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex w-full">
          <Sidebar />
        <div className=" xl:ml-[3rem] 2xl:ml-[15rem] h-fit w-[855px] ">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/company" className="px-2">
              Company
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/company/manage-company">
              Manage Companies
            </Link>
          </div>

          {/* page Heading*/}
          <div>
            <section className="flex items-center">
              <BsBoxes style={iconStyles} />
              <p className="text-3xl mx-1">Manage Companies</p>
              <p className="text-[#0000A3] mt-2">info</p>
            </section>
          </div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>

          <div className="flex items-center space-x-3 justify-end w-[1050px] mr-4">
            <div>
              <button
                className="ml-5 bg-[#0000A3]  mt-1 py-[8px] px-6 text-white"
                type="button"
                onClick={() => {
                  navigate("/company/create-company");
                }}
              >
                New Company
              </button>
            </div>
            <div className="flex justify-end mt-1">
              <section ref={actionRef}>
                <div className="flex w-full justify-center items-center">
                  <div className="flex justify-center items-center">
                    <div
                      onClick={() => setAction((prev) => !prev)}
                      className="hover:cursor-pointer px-[10px] py-2 text-md border border-gray-400 bg-gray-100 flex items-center"
                    >
                      Actions
                      <div className="pl-2">
                        <AiOutlineCaretDown />
                      </div>
                    </div>
                    {action && (
                      <div className="hover:cursor-pointer absolute mt-[9rem] ml-1 space-y-1 flex z-[1] flex-col justify-center w-[98.4667px] h-fit bg-white shadow-lg py-4 ">
                        <p className=" hover:bg-gray-200 capitalize text-md font-medium text-center">
                          Update
                        </p>
                        <button
                          onClick={handleDeleteClick}
                          className="hover:bg-gray-200 capitalize text-md font-medium text-center"
                        >
                          delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>

          {!loading && (
            <div className="h-[450px] w-[1050px] pt-8 mr-4">
              <DataGrid
                style={{ overflowY: "scroll" }}
                getRowId={(row) => row.company_id}
                rows={rows}
                checkboxSelection
                columns={columns}
                onRowSelectionModelChange={(row) => handleSelectedRows(row)}
                hideFooter
                hideFooterPagination
                hideFooterSelectedRowCount
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};